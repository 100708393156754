import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DsSpotlightItem } from './spotlight-item';
import { SpotlightActions } from './spotlight.actions';

export interface SpotlightState extends EntityState<DsSpotlightItem> {
  idOfOpenSpotlight?: string;
}

export const spotlightAdapter: EntityAdapter<DsSpotlightItem> =
  createEntityAdapter<DsSpotlightItem>({
    sortComparer: false,
  });

const initialState: SpotlightState = spotlightAdapter.getInitialState({
  isOpen: false,
});

const reducer = createReducer(
  initialState,
  on(SpotlightActions.RegisterSpotlight, (state, { id, tags }) => {
    // always update local storage meta data
    updateLocalStorage([
      {
        ...localStorageSpotlights[id],
        tags: tags,
      },
    ]);

    // only register unseen spotlights
    // so user only navigate between unseen spotlights
    if (!state.entities[id] && !wasItemShown(id)) {
      const item = {
        id: id,
        seen: false,
        tags: tags,
      };
      const newState = spotlightAdapter.addOne(item, state);
      if (!state.idOfOpenSpotlight) {
        return changeSpotlightToOpen(item, newState);
      } else {
        return newState;
      }
    } else {
      return state.idOfOpenSpotlight
        ? state
        : openNextUnseenSpotlight(state, -1);
    }
  }),
  on(SpotlightActions.UnregisterSpotlight, (state, { id }) => ({
    ...spotlightAdapter.removeOne(id, state),
    idOfOpenSpotlight:
      id === state.idOfOpenSpotlight ? undefined : state.idOfOpenSpotlight,
  })),
  on(SpotlightActions.OpenNext, (state) => {
    const spotlights = Object.values(state.entities);
    const index = spotlights.findIndex(
      (x) => x?.id === state.idOfOpenSpotlight,
    );
    return changeSpotlightToOpen(spotlights[index + 1], state);
  }),
  on(SpotlightActions.OpenPrevious, (state) => {
    const spotlights = Object.values(state.entities);
    const index = spotlights.findIndex(
      (x) => x?.id === state.idOfOpenSpotlight,
    );
    return changeSpotlightToOpen(spotlights[index - 1], state);
  }),
  on(SpotlightActions.Close, (state) => ({
    ...spotlightAdapter.removeMany((entity) => entity.seen, state),
    idOfOpenSpotlight: undefined,
  })),
  on(SpotlightActions.SetSpotlightAsUnseen, (state, { id }) => {
    const item = localStorageSpotlights[id];
    if (item) {
      const changedItem = { ...item, seen: false };
      const newState = spotlightAdapter.upsertOne(changedItem, state);
      updateLocalStorage([changedItem]);
      return newState;
    }
    return state;
  }),
  on(SpotlightActions.SetSpotlightsAsUnseenByTags, (state, { tags }) => {
    const changedItems = Object.entries(localStorageSpotlights)
      .map(([, item]) => item)
      .filter((item): item is DsSpotlightItem => item !== undefined)
      .filter((item) => item?.tags?.some((tag) => tags.includes(tag)))
      .map((item) => ({ ...item, seen: false }));

    const newState = spotlightAdapter.upsertMany(changedItems, state);
    updateLocalStorage(changedItems);
    return newState;
  }),
  on(SpotlightActions.SetAllSpotlightAsUnseen, (state) => {
    const changedItems = Object.entries(localStorageSpotlights)
      .map(([, item]) => item)
      .filter((item): item is DsSpotlightItem => item.id !== undefined)
      .map((item) => ({ ...item, seen: false }));
    const newState = spotlightAdapter.upsertMany(changedItems, state);
    updateLocalStorage(changedItems);
    window.location.reload();
    return newState;
  }),
);

export function spotlightReducer(
  state: SpotlightState | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function changeSpotlightToOpen(
  item: DsSpotlightItem | undefined,
  state: SpotlightState,
) {
  if (item) {
    const changedItem = { ...item, seen: true };
    const newState = spotlightAdapter.updateOne(
      { id: item.id, changes: changedItem },
      state,
    );
    updateLocalStorage([changedItem]);
    return {
      ...newState,
      idOfOpenSpotlight: item.id,
    };
  } else {
    return {
      ...state,
      idOfOpenSpotlight: undefined,
    };
  }
}

function openNextUnseenSpotlight(state: SpotlightState, currentIndex: number) {
  const spotlights = Object.values(state.entities);
  return changeSpotlightToOpen(
    spotlights.find((x, index) => index > currentIndex && !x?.seen),
    state,
  );
}

// local storage
let localStorageSpotlights: { [key: string]: DsSpotlightItem } = JSON.parse(
  localStorage.getItem('spotlights') || '{}',
);

function updateLocalStorage(items: DsSpotlightItem[]) {
  items.forEach((x) => (localStorageSpotlights[x.id] = x));
  localStorage.setItem('spotlights', JSON.stringify(localStorageSpotlights));
}

function wasItemShown(id: string) {
  return !!localStorageSpotlights[id]?.seen;
}

export function clearLocalStorage() {
  localStorage.removeItem('spotlights');
  localStorageSpotlights = {};
}

export function setLocalStorage(storedSpotlights: {
  [key: string]: DsSpotlightItem;
}) {
  localStorageSpotlights = storedSpotlights;
}
