import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RoutesInformation } from '../models/routes-information';
import { RootSelectors, RootState } from '../store';

@Injectable()
export class MarketModelRouteGuard {
  constructor(private rootStore: Store<RootState>) {}

  public static hasRoute(
    routesInformation: RoutesInformation,
    url: string,
  ): boolean {
    for (const route of routesInformation.visibleRoutes) {
      if (url.match(route)) {
        return true;
      }
    }
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.rootStore.select(RootSelectors.routesInformation).pipe(
      filter((x) => x != null),
      map((routesInformation) => {
        if (routesInformation == null) {
          throw new Error(
            '[MarketModelRouteGuard] RoutesInformation could not be determined!',
          );
        }

        const activatedChild = route.children?.find(
          (c) => c.root.url === state.root.url,
        );

        if (activatedChild != null) {
          const moduleName = activatedChild.data && activatedChild.data.module;

          if (moduleName == null) {
            throw new Error(
              '[MarketModelRouteGuard] No module name provided in route data! Root url: ' +
                state.root.url,
            );
          }
        }

        return MarketModelRouteGuard.hasRoute(
          routesInformation,
          state.url.replace('/', ''),
        );
      }),
    );
  }
}
