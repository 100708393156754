<ng-container [gaCategoryGroup]="googleAnalyticsConstants.sidebar.sidebarName">
  <div class="drawer-mobile-header padding">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <h1
          class="default margin-bottom-0 cursor-pointer"
          (click)="navigateToDashboard()"
          [gaClickListener]="
            googleAnalyticsConstants.sidebar.clickListener.navigateToDashboard
          "
        >
          Paldesk
        </h1>
        <mat-icon class="pointer" (click)="close()">close</mat-icon>
      </div>

      <img class="logo-sidebar" src="assets/img/logo.svg" />
      <h4
        class="app-name-sidebar no-margin-bottom"
        *ngIf="config.appName"
        fxLayout="row"
        fxLayoutAlign="start center"
        [fxLayoutGap]="0.25 | dsSpacing"
      >
        @if (appIcon; as icon) {
          @if (!(icon | isPdIcon)) {
            <mat-icon>{{ icon }}</mat-icon>
          } @else {
            <mat-icon fontSet="pd" class="pd-icon" [fontIcon]="icon"></mat-icon>
          }
        }
        <span
          [fxHide]="
            config.appName === 'Paldesk' && appNameOverride !== 'Paldiag CMI'
          "
        >
          {{ appNameOverride ?? config.appName | uppercase }}
        </span>
      </h4>
    </div>
  </div>
  <ds-menu-v2 *ngIf="menu" [applicationMenu]="menu"></ds-menu-v2>
  <mat-divider *ngIf="menu"></mat-divider>
  <div class="menu-container" fxLayout="column">
    <div *ngIf="visibleMenuItems.showApps">
      <button
        class="full-width btn"
        fxLayout="row"
        fxLayoutAlign="start center"
        [fxLayoutGap]="0.25 | dsSpacing"
        (click)="appsExpanded = !appsExpanded"
        [class.expanded]="appsExpanded"
        mat-button
        tabindex="-1"
        [gaClickListener]="
          googleAnalyticsConstants.sidebar.clickListener.showApps
        "
        data-cy="sidebar-apps-button"
      >
        <div
          fxLayoutAlign="center center"
          class="full-width left-text menu-expand"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon>apps</mat-icon>
          <span
            fxFlex="1 1 auto"
            [class.fw-normal]="!appsExpanded"
            [class.fw-bold]="appsExpanded"
            >{{ 'navigation.apps' | translate }}</span
          >
          <mat-icon
            class="width-auto"
            [@rotate90]="appsExpanded ? 'rotated' : 'default'"
          >
            chevron_right
          </mat-icon>
        </div>
      </button>

      <div [@openClose]="appsExpanded" class="overflow-hidden">
        <ds-new-paldesk-apps-sidebar></ds-new-paldesk-apps-sidebar>
      </div>
    </div>

    <div *ngIf="visibleMenuItems.showProducts">
      <button
        class="full-width btn"
        fxLayout="row"
        fxLayoutAlign="start center"
        [fxLayoutGap]="0.25 | dsSpacing"
        (click)="productsExpanded = !productsExpanded"
        [class.expanded]="productsExpanded"
        mat-button
        [gaClickListener]="
          googleAnalyticsConstants.sidebar.clickListener.showProducts
        "
      >
        <div
          class="full-width left-text menu-expand"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon>description</mat-icon>
          <span
            fxFlex="1 1 auto"
            [class.fw-normal]="!productsExpanded"
            [class.fw-bold]="productsExpanded"
            >{{ 'navigation.product_documents' | translate }}</span
          >
          <mat-icon
            class="width-auto"
            [@rotate90]="productsExpanded ? 'rotated' : 'default'"
          >
            chevron_right
          </mat-icon>
        </div>
      </button>
      <div [@openClose]="productsExpanded" class="overflow-hidden">
        <ds-new-paldesk-documents-sidebar
          [documentType]="documentType.product"
        ></ds-new-paldesk-documents-sidebar>
      </div>
    </div>

    <div *ngIf="visibleMenuItems.showDocuments">
      <button
        class="full-width btn"
        fxLayout="row"
        fxLayoutAlign="start center"
        [fxLayoutGap]="0.25 | dsSpacing"
        (click)="documentsExpanded = !documentsExpanded"
        [class.expanded]="documentsExpanded"
        mat-button
        [gaClickListener]="
          googleAnalyticsConstants.sidebar.clickListener.showServiceDocuments
        "
      >
        <div
          class="full-width left-text menu-expand"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon>settings_application</mat-icon>
          <span
            fxFlex="1 1 auto"
            [class.fw-normal]="!documentsExpanded"
            [class.fw-bold]="documentsExpanded"
            >{{ 'navigation.service_documents' | translate }}</span
          >
          <mat-icon
            class="width-auto"
            [@rotate90]="documentsExpanded ? 'rotated' : 'default'"
          >
            chevron_right
          </mat-icon>
        </div>
      </button>
      <div [@openClose]="documentsExpanded" class="overflow-hidden">
        <ds-new-paldesk-documents-sidebar
          [documentType]="documentType.service"
        ></ds-new-paldesk-documents-sidebar>
      </div>
    </div>

    <div *ngIf="visibleMenuItems.showHelp">
      <button
        class="full-width btn"
        fxLayout="row"
        fxLayoutAlign="start center"
        [fxLayoutGap]="0.25 | dsSpacing"
        (click)="helpAndFeedbackExpanded = !helpAndFeedbackExpanded"
        [class.expanded]="helpAndFeedbackExpanded"
        mat-button
        [gaClickListener]="
          googleAnalyticsConstants.sidebar.clickListener.showHelp
        "
      >
        <div
          class="full-width left-text menu-expand"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon>support_agent</mat-icon>
          <span
            fxFlex="1 1 auto"
            [class.fw-normal]="!helpAndFeedbackExpanded"
            [class.fw-bold]="helpAndFeedbackExpanded"
            >{{ 'navigation.help_feedback' | translate }}</span
          >
          <mat-icon
            class="width-auto"
            [@rotate90]="helpAndFeedbackExpanded ? 'rotated' : 'default'"
          >
            chevron_right
          </mat-icon>
        </div>
      </button>
      <div [@openClose]="helpAndFeedbackExpanded" class="overflow-hidden">
        <ds-sidebar-help-and-feedback></ds-sidebar-help-and-feedback>
      </div>
    </div>
  </div>
</ng-container>
