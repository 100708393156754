/**
 * Palfinger MarketModel API
 * API Gateway for MarketModel
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum MarketModelRoleType {
    regionManager = 'regionManager',
    coordinator = 'coordinator',
    salesDirector = 'salesDirector',
    areaSalesManager = 'areaSalesManager',
    inputUser = 'inputUser',
    productLineManager = 'productLineManager',
    leadAreaSalesManager = 'leadAreaSalesManager'
};

