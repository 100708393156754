/* eslint-disable no-bitwise */
export class GuidUtils {
  static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }

  /* Source: http://guid.us/GUID/JavaScript */
  static guid(): string {
    // then to call it, plus stitch in '4' in the third group
    return (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-4' +
      this.S4().substr(0, 3) +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    ).toLowerCase();
  }

  private static S4(): string {
    // eslint-disable no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
}
