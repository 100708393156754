import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsBannerComponent } from './banner.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    DsTextIndicatorModule,
    FlexLayoutModule,
  ],
  declarations: [DsBannerComponent],
  exports: [DsBannerComponent],
})
export class DsBannerModule {}
