import { InjectionToken } from '@angular/core';

// work around for https://github.com/angular/angular-cli/issues/8097
export const IDENTITY_SERVER_AUTH_CONF = new InjectionToken<string>(
  'IDENTITY_SERVER_AUTH_CONF',
);

export const ERROR_HANDLING_ROUTES = new InjectionToken<string[]>(
  'ERROR_HANDLING_ROUTES',
);

export const HttpInterceptorTokens = {
  identityServerAuthConf: IDENTITY_SERVER_AUTH_CONF,
  errorHandlingRoutes: ERROR_HANDLING_ROUTES,
};
