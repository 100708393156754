<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  fxLayoutAlign="center center"
>
  <img src="assets/svg/icons/access-denied.svg" />

  <h2 class="no-margin-top">{{ 'special_pages.access_denied' | translate }}</h2>

  <p>
    {{ 'special_pages.access_denied_info' | translate }} <br />
    {{ 'special_pages.access_denied_prompt' | translate }}
  </p>

  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    [fxLayoutGap]="0.5 | dsSpacing"
  >
    <a
      mat-stroked-button
      [href]="
        'mailto:' +
        supportMail +
        '?subject=Access%20denied%20-%20Access%20request'
      "
    >
      {{ 'special_pages.request_access' | translate }}</a
    >
    <a mat-button [href]="homeAdress">
      {{ 'special_pages.home' | translate }}
    </a>
  </div>
</div>
