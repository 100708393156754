<div
  class="container help-section"
  fxLayout="column"
  [gaCategoryGroup]="googleAnalyticsConstants.sidebar.sidebarName"
  *pdLet="userService.isAuthorized$ | async as isAuthorized"
>
  @if ((this.config.appHelpPath || this.config.appNewsPath) && isAuthorized) {
    <h4 data-cy="help-section-header">
      {{ 'header.help_feedback_menu.help' | translate | uppercase }}
    </h4>

    @if (this.config.appHelpPath) {
      <button mat-button class="align-left" data-cy="menu-item">
        <div
          (click)="navigateTo(this.config.appHelpPath)"
          [gaClickListener]="
            googleAnalyticsConstants.sidebar.clickListener.navigateToAppHelp
          "
        >
          <div
            class="side-menu-item-help"
            fxLayout="row"
            fxLayoutAlign="start end"
          >
            <div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing">
              <mat-icon class="icon-small" data-cy="menu-icon"
                >rocket_launch</mat-icon
              >
              <p class="no-margin">
                {{
                  'header.help_feedback_menu.tutorials_and_getting_started'
                    | translate
                }}
              </p>
            </div>
            <mat-icon class="open-new">open_in_new</mat-icon>
          </div>
        </div>
      </button>
    }
    @if (this.config.appNewsPath) {
      <button mat-button class="align-left" data-cy="menu-item">
        <div
          (click)="navigateTo(this.config.appNewsPath)"
          [gaClickListener]="
            googleAnalyticsConstants.sidebar.clickListener.navigateToAppNews
          "
        >
          <div fxLayout="row" fxLayoutAlign="start end">
            <div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing">
              <mat-icon class="icon-small" data-cy="menu-icon"
                >new_releases</mat-icon
              >
              <p class="no-margin">
                {{ 'header.help_feedback_menu.whats_new' | translate }}
              </p>
            </div>
            <mat-icon class="open-new">open_in_new</mat-icon>
          </div>
        </div>
      </button>
    }
  }
  <h4>{{ 'header.help_feedback_menu.support' | translate | uppercase }}</h4>

  <button mat-button class="align-left" data-cy="menu-item">
    <div
      (click)="openFreshdeskWidget('help')"
      [gaClickListener]="
        googleAnalyticsConstants.sidebar.clickListener.openFreshdeskHelp
      "
    >
      <div fxLayout="row" fxLayoutAlign="start end">
        <div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing">
          <mat-icon class="icon-small" data-cy="menu-icon"
            >support_agent</mat-icon
          >
          <p class="no-margin">
            {{ 'header.help_feedback_menu.contact_support' | translate }}
          </p>
        </div>
      </div>
    </div>
  </button>
  @if (isAuthorized) {
    <button mat-button class="align-left" data-cy="menu-item">
      <div
        (click)="openFeedbackDialog()"
        [gaClickListener]="
          googleAnalyticsConstants.sidebar.clickListener.openFeedback
        "
      >
        <div fxLayout="row" fxLayoutAlign="start end">
          <div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing">
            <mat-icon class="icon-small" data-cy="menu-icon"
              >thumbs_up_down</mat-icon
            >
            <p class="no-margin">
              {{ 'header.help_feedback_menu.give_feedback' | translate }}
            </p>
          </div>
        </div>
      </div>
    </button>

    <button mat-button class="align-left" data-cy="menu-item">
      <div
        (click)="openFreshdeskWidget('idea')"
        [gaClickListener]="
          googleAnalyticsConstants.sidebar.clickListener.openFreshdeskIdea
        "
      >
        <div fxLayout="row" fxLayoutAlign="start end">
          <div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing">
            <mat-icon class="icon-small" data-cy="menu-icon"
              >lightbulb</mat-icon
            >
            <p class="no-margin">
              {{ 'header.help_feedback_menu.submit_idea' | translate }}
            </p>
          </div>
        </div>
      </div>
    </button>
  }
  <button mat-button class="align-left" data-cy="menu-item">
    <div
      (click)="navigateTo(this.config.paldeskSupportPath)"
      [gaClickListener]="
        googleAnalyticsConstants.sidebar.clickListener.navigateToPaldeskSupport
      "
    >
      <div fxLayout="row" fxLayoutAlign="start end">
        <div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing">
          <mat-icon class="icon-small" data-cy="menu-icon">traffic</mat-icon>
          <p class="no-margin">
            {{ 'header.help_feedback_menu.paldesk_status' | translate }}
          </p>
        </div>
        <mat-icon class="open-new">open_in_new</mat-icon>
      </div>
    </div>
  </button>
</div>
