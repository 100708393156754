import { Pipe, PipeTransform } from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';

/**
 * The purpose of this pipe is to make spacing globally control able
 * with this we can create linting rules and refactor design changes easily
 */
@Pipe({ name: 'dsSpacing' })
export class DsSpacingPipe implements PipeTransform {
  transform(factor: number): string {
    return factor * dsConfig.spacing + 'px';
  }
}
