export interface PrivacyPolicyLink {
  language: string;
  url: string;
}

export const PRIVACY_POLICY_LINKS: PrivacyPolicyLink[] = [
  {
    language: 'EN',
    url: 'https://www.palfinger.com/privacypolicy/paldesk_en.pdf',
  },
  {
    language: 'DE',
    url: 'https://www.palfinger.com/privacypolicy/paldesk_de.pdf',
  },
];
