import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SurveyPipe } from './pipes/survey.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SurveyPipe],
  exports: [SurveyPipe],
})
export class SurveyModule {}
