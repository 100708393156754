import { Inject, Injectable } from '@angular/core';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from '../../config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  currentEnvironment: string;

  private readonly environmentUrls = {
    dev: {
      apiBasePath: 'https://palfinger-test.azure-api.net',
      apiGatewayBasePath: 'https://api.test.paldesk.palfinger.com',

      equipmentScreenUrl: 'https://equipment-test.palfinger.com/',
      productScreenUrl: 'https://product-test.palfinger.com/',
      dashboardUrl: 'https://paldesk-test.palfinger.com/',
      statusPageUrl: 'https://status-test.palfinger.com/',
    },
    test: {
      apiBasePath: 'https://palfinger-test.azure-api.net',
      apiGatewayBasePath: 'https://api.test.paldesk.palfinger.com',

      equipmentScreenUrl: 'https://equipment-test.palfinger.com/',
      productScreenUrl: 'https://product-test.palfinger.com/',
      dashboardUrl: 'https://paldesk-test.palfinger.com/',
      statusPageUrl: 'https://status-test.palfinger.com/',
    },
    prod: {
      apiBasePath: 'https://palfinger.azure-api.net',
      apiGatewayBasePath: 'https://api.prod.paldesk.palfinger.com',

      equipmentScreenUrl: 'https://equipment.palfinger.com/',
      productScreenUrl: 'https://product.palfinger.com/',
      dashboardUrl: 'https://paldesk.palfinger.com/',
      statusPageUrl: 'https://status.palfinger.com/',
    },
  };

  constructor(
    @Inject(DS_APP_WRAPPER_CONFIG) private _config: DsAppWrapperConfig,
  ) {
    this.currentEnvironment = this._config.environment;
  }

  getApiBasePath(): string {
    return this.getEnvironmentConfig().apiBasePath;
  }

  getApiGatewayBasePath(): string {
    return this.getEnvironmentConfig().apiGatewayBasePath;
  }

  getEquipmentScreenUrl(): string {
    return this.getEnvironmentConfig().equipmentScreenUrl;
  }

  getProductScreenUrl(): string {
    return this.getEnvironmentConfig().productScreenUrl;
  }

  getDashboardUrl(): string {
    return this.getEnvironmentConfig().dashboardUrl;
  }

  getStatusPageUrl(): string {
    return this.getEnvironmentConfig().statusPageUrl;
  }

  private getEnvironmentConfig() {
    const envConfig = this.environmentUrls[this.currentEnvironment];
    if (!envConfig) {
      throw new Error(`Unknown environment: ${this.currentEnvironment}`);
    }
    return envConfig;
  }
}
