/**
 * Palfinger.Microservice.Search
 * Search API
 *
 * The version of the OpenAPI document: v1
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentV2AllOf } from './documentV2AllOf';
import { SearchResultItem } from './searchResultItem';


export interface DocumentV2 { 
    
    search_score: number;
    
    discriminator: DocumentV2Discriminator;
    
    titles: { [key: string]: string | undefined; };
    
    links: { [key: string]: string | undefined; };
    
    document_type: string;
    
    version?: string | null;
    
    equipments: Array<string>;
    
    valid_from?: string | null;
    
    valid_to?: string | null;
    
    product_lines: Array<string> | null;
    
    highlighted_content?: string | null;
}
export enum DocumentV2Discriminator {
    Document = 'Document',
    Equipment = 'Equipment',
    Product = 'Product',
    Sparepart = 'Sparepart'
};



