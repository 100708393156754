import { Pipe, PipeTransform } from '@angular/core';
import { Application, ApplicationCategory } from '@features/applications';

export const columnSwitchBreakpoint = 1272;
@Pipe({
  name: 'orderCategories',
  standalone: true,
})
export class OrderCategoriesPipe implements PipeTransform {
  private categoryOrder: string[] = [
    'Sales',
    'Marketing Support',
    'Service',
    'America do Sul',
    'Suppliers',
    'Special roles',
    'Administration',
    'Others',
    'Customer',
  ];

  transform(
    categories: ApplicationCategory[] | undefined | null,
  ): ApplicationCategory[] {
    return (categories || [])
      .filter((category) => this.categoryOrder.includes(category.name))
      .sort((a, b) => this.sortCategories(a, b))
      .map((category) => this.sortAndOrganizeApplications(category));
  }

  private sortCategories(
    a: ApplicationCategory,
    b: ApplicationCategory,
  ): number {
    return (
      this.categoryOrder.indexOf(a.name) - this.categoryOrder.indexOf(b.name)
    );
  }

  private sortAndOrganizeApplications(
    category: ApplicationCategory,
  ): ApplicationCategory {
    const sortedApps = this.sortApplications(category.applications);
    category.rows = this.organizeApplicationsIntoRows(sortedApps);
    const setFilteredApplications = category.setFilteredApplications;
    return {
      ...category,
      applications: sortedApps,
      setFilteredApplications,
    };
  }

  private sortApplications(applications: Application[]): Application[] {
    const favoriteApps = applications
      .filter((app) => app.favorite)
      .sort((a, b) => a.name.localeCompare(b.name));
    const nonFavoriteApps = applications
      .filter((app) => !app.favorite)
      .sort((a, b) => a.name.localeCompare(b.name));
    return [...favoriteApps, ...nonFavoriteApps];
  }

  private organizeApplicationsIntoRows(
    applications: Application[],
  ): Array<Array<Application | undefined>> {
    const screenWidth = window.innerWidth;
    const maxColumnCount = screenWidth < columnSwitchBreakpoint ? 2 : 3;
    const rowCount = Math.ceil(applications.length / maxColumnCount);
    const rows = Array.from(
      { length: rowCount },
      () => new Array<Application | undefined>(maxColumnCount),
    );

    let rowIndex = 0;
    let columnIndex = 0;
    applications.forEach((app) => {
      rows[rowIndex][columnIndex] = app;
      rowIndex++;
      if (rowIndex === rowCount) {
        rowIndex = 0;
        columnIndex++;
      }
    });

    this.adjustLastColumnIfEmpty(rows, maxColumnCount, rowCount);

    return rows;
  }

  private adjustLastColumnIfEmpty(
    rows: Array<Array<Application | undefined>>,
    columnCount: number,
    rowCount: number,
  ): void {
    //if first row and last column is empty
    if (rows[0] && rows[0][columnCount - 1] === undefined) {
      //move last item from previous column to last column
      for (let rowIndex = rowCount - 1; rowIndex > 0; rowIndex--) {
        if (rows[rowIndex][columnCount - 2] !== undefined) {
          rows[0][columnCount - 1] = rows[rowIndex][columnCount - 2];
          rows[rowIndex][columnCount - 2] = undefined;
          break;
        }
      }
    }
  }
}
