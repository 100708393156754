<div>
  <h5 class="no-margin">
    {{ title | translate }}
  </h5>
  <ng-container *ngFor="let child of children">
    <ng-container *ngTemplateOutlet="link; context: { $implicit: child }">
    </ng-container>
    <div class="expanding-panel" [@slideUpDown] *ngIf="isOpened(child)">
      <div *ngFor="let innerChild of child.children">
        <ng-container
          *ngTemplateOutlet="link; context: { $implicit: innerChild }"
        >
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #link let-item>
  <a
    *ngIf="item.externalLink || !item.routerLink; else routerLnk"
    mat-button
    class="no-padding full-width"
    [href]="item.externalLink"
    [matBadge]="item.badge"
    [matBadgeHidden]="!item.badge"
    matBadgeColor="primary"
    matBadgeSize="small"
    matBadgePosition="after"
    [matBadgeOverlap]="false"
    (click)="onItemClick(item); (!!item.externalLink)"
  >
    <span fxLayoutAlign="space-between center">
      <span class="container cut-text icon-spacer relative-position">{{
        item.title | translate
      }}</span>
      <mat-icon class="external" *ngIf="!!item.externalLink"
        >open_in_new</mat-icon
      >
    </span></a
  >
  <ng-template #routerLnk>
    <div
      class="relative-position"
      [routerLink]="[item.routerLink]"
      [queryParamsHandling]="item.queryParamsHandling"
    >
      <button
        mat-button
        class="no-padding full-width"
        routerLinkActive="active"
        (isActiveChange)="openItem($event, item)"
        #rla="routerLinkActive"
        [class.active]="rla.isActive"
        [class.neutral-ribbon]="rla.isActive && !item.children?.length"
        (click)="item?.onClick && item.onClick()"
      >
        <div fxLayout>
          <span class="container relative-position cut-text">{{
            item.title | translate
          }}</span>
          <div class="badge relative-position" *ngIf="item.badge">
            {{ item.badge }}
          </div>
        </div>
      </button>
      <button
        class="expand-button"
        mat-icon-button
        *ngIf="item.children?.length"
        (click)="$event.stopPropagation(); toggleOpened(item)"
      >
        <mat-icon>{{
          isOpened(item) ? 'expand_more' : 'chevron_right'
        }}</mat-icon>
      </button>
    </div>
  </ng-template>
</ng-template>
