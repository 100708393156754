import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  FreshdeskWebwidgetConfig,
  FreshdeskWebwidgetConfigToken,
} from './freshdesk-widget-config';

@NgModule({
  imports: [CommonModule],
})
export class SharedLibFeaturesFreshdeskWidgetModule {
  static forRoot(
    config: FreshdeskWebwidgetConfig,
  ): ModuleWithProviders<SharedLibFeaturesFreshdeskWidgetModule> {
    return {
      ngModule: SharedLibFeaturesFreshdeskWidgetModule,
      providers: [
        {
          provide: FreshdeskWebwidgetConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
