export class Utils {
  static ensureArray<T>(a: T | T[]): T[] {
    return Array.isArray(a) ? a : [a];
  }

  /* finds the intersection of
   * two arrays in a simple fashion.
   *
   * PARAMS
   *  a - first array, must already be sorted
   *  b - second array, must already be sorted
   *
   * NOTES
   *
   *  Should have O(n) operations, where n is
   *    n = MIN(a.length(), b.length())
   */
  static Intersect_safe(a: string[], b: string[]): string[] {
    a = a.map((val) => val.toLocaleLowerCase()).sort();
    b = b.map((val) => val.toLocaleLowerCase()).sort();

    let ai = 0;
    let bi = 0;
    const result: string[] = [];

    while (ai < a.length && bi < b.length) {
      if (a[ai] < b[bi]) {
        ai++;
      } else if (a[ai] > b[bi]) {
        bi++;
      } else {
        /* they're equal */
        result.push(a[ai]);
        ai++;
        bi++;
      }
    }

    return result;
  }

  static intersectEnums<T>(a: T | T[], b: T | T[]): T[] {
    const aa = Utils.ensureArray(a);
    const bb = Utils.ensureArray(b);

    const result: T[] = [];
    aa.forEach((aaVal) => {
      if (bb.find((p) => p === aaVal) != null) {
        result.push(aaVal);
      }
    });

    bb.forEach((bbVal) => {
      if (
        aa.find((p) => p === bbVal) != null &&
        result.find((p) => p === bbVal) == null
      ) {
        result.push(bbVal);
      }
    });

    return result;
  }
}
