<mat-card
  [gaCategoryGroup]="googleAnalyticsConstants.header.headerName"
  [gaLabelGroup]="menuData.data"
>
  <mat-card-content>
    <div
      class="container"
      [ngStyle]="
        documentCategories()?.length
          ? (documentCategories()?.length || 0 | gridRowColumnStyle)
          : ''
      "
    >
      <div *ngFor="let documentCategory of documentCategories()">
        <button
          class="btn"
          mat-button
          (click)="openInNew(documentCategory.code)"
          [gaClickListener]="
            googleAnalyticsConstants.header.clickListener.navigateToDocument +
            documentCategory.code
          "
        >
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            [fxLayoutGap]="0.5 | dsSpacing"
          >
            <img
              data-cy="document-category-icon"
              class="img"
              [src]="
                documentCategory.icon ||
                'https://static-test.paldesk.palfinger.com/palipedia/images/icons/Service-3623cf55-3f3c-4ff8-9133-b8fd710edc95.jpg  '
              "
            />
            <span>{{ documentCategory.name }}</span>
          </div>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
