import { Inject, Injectable, InjectionToken } from '@angular/core';

export const GoogleAnalyticsTrackingId = new InjectionToken('GA_TRACKING_ID');
@Injectable({
  providedIn: 'root',
})
/**
 * Google Analytics Service Wrapper for initializing of the GA Service
 */
export class GaService {
  constructor(@Inject(GoogleAnalyticsTrackingId) private gaId: string) {}

  create() {
    this.ga('create', this.gaId, 'auto');
  }

  ga(...args: any[]) {
    const gaFn = (window as any)['ga'];
    if (gaFn) {
      gaFn(...args);
    }
  }
}
