<div
  *pdLet="userService.isAuthorized$ | async as isAuthorized"
  id="help-feedback-menu-wrapper"
  data-cy="help-feedback-menu-wrapper"
  [gaCategoryGroup]="googleAnalyticsConstants.header.headerName"
>
  <mat-card (click)="$event.stopPropagation()">
    <mat-card-content fxLayout="column">
      @if (
        (this.config.appHelpPath || this.config.appNewsPath) && isAuthorized
      ) {
        <div class="help-section">
          <h4 class="section-header-background" data-cy="help-section-header">
            {{ 'header.help_feedback_menu.help' | translate | uppercase }}
          </h4>
          <div
            fxLayoutAlign="space-between baseline"
            [fxLayoutGap]="2 | dsSpacing"
          >
            @if (this.config.appHelpPath) {
              <button
                mat-button
                class="mega-menu-item-wrap help-menu-item"
                data-cy="help-menu-item"
              >
                <div
                  class="mega-menu-item mega-menu-item-help"
                  fxLayout="row"
                  fxLayoutAlign="space-between baseline"
                  (click)="navigateTo(this.config.appHelpPath)"
                  [gaClickListener]="
                    googleAnalyticsConstants.header.clickListener
                      .navigateToAppHelp
                  "
                >
                  <div fxLayout="row">
                    <mat-icon class="help-menu-item-icon" data-cy="menu-icon"
                      >rocket_launch</mat-icon
                    >
                    <div
                      fxLayout="column wrap"
                      fxLayoutAlign="flex-start"
                      class="mega-menu-item-text"
                    >
                      <p>
                        {{
                          'header.help_feedback_menu.tutorials_and_getting_started'
                            | translate
                        }}
                      </p>
                      <small
                        >{{
                          'header.help_feedback_menu.tutorials_explanation'
                            | translate
                        }}&nbsp;{{ this.config.appName }}</small
                      >
                    </div>
                  </div>
                </div>
              </button>
            }
            @if (this.config.appNewsPath) {
              <button
                mat-button
                class="mega-menu-item-wrap"
                data-cy="news-menu-item"
              >
                <div
                  class="mega-menu-item"
                  fxLayoutAlign="space-between baseline"
                  (click)="navigateTo(this.config.appNewsPath)"
                  [gaClickListener]="
                    googleAnalyticsConstants.header.clickListener
                      .navigateToAppNews
                  "
                >
                  <div fxLayout="row">
                    <mat-icon data-cy="menu-icon">new_releases</mat-icon>
                    <div
                      fxLayout="column wrap"
                      fxLayoutAlign="flex-start"
                      class="mega-menu-item-text"
                    >
                      <p>
                        {{ 'header.help_feedback_menu.whats_new' | translate }}
                      </p>
                      <small
                        >{{
                          'header.help_feedback_menu.whats_new_explanation'
                            | translate: { appName: this.config.appName }
                        }}
                      </small>
                    </div>
                  </div>
                </div>
              </button>
            }
          </div>
        </div>
      }

      <h4 class="section-header-background" data-cy="support-section-header">
        {{ 'header.help_feedback_menu.support' | translate | uppercase }}
      </h4>
      <div
        [fxLayout]="isAuthorized ? 'column' : 'row'"
        [fxLayoutGap]="isAuthorized ? '0px' : (2 | dsSpacing)"
      >
        <div
          fxLayoutAlign="space-between baseline"
          [fxLayoutGap]="2 | dsSpacing"
        >
          <button
            mat-button
            class="mega-menu-item-wrap"
            data-cy="support-menu-item"
          >
            <div
              class="mega-menu-item"
              fxLayout="row"
              fxLayoutAlign="space-between baseline"
              (click)="openFreshdeskWidget('help')"
              [gaClickListener]="
                googleAnalyticsConstants.header.clickListener.openFreshdeskHelp
              "
            >
              <div fxLayout="row">
                <mat-icon data-cy="menu-icon">support_agent</mat-icon>
                <div
                  fxLayout="column wrap"
                  fxLayoutAlign="flex-start"
                  class="mega-menu-item-text"
                >
                  <p>
                    {{
                      'header.help_feedback_menu.contact_support' | translate
                    }}
                  </p>
                  <small>{{
                    'header.help_feedback_menu.contact_support_explanation'
                      | translate
                  }}</small>
                </div>
              </div>
            </div>
          </button>
          @if (isAuthorized) {
            <button
              mat-button
              class="mega-menu-item-wrap"
              data-cy="feedback-menu-item"
            >
              <div
                class="mega-menu-item"
                fxLayout="row"
                fxLayoutAlign="space-between baseline"
                (click)="openFeedbackDialog()"
                [gaClickListener]="
                  googleAnalyticsConstants.header.clickListener.openFeedback
                "
              >
                <div fxLayout="row">
                  <mat-icon data-cy="menu-icon">thumbs_up_down</mat-icon>
                  <div
                    fxLayout="column wrap"
                    fxLayoutAlign="flex-start"
                    class="mega-menu-item-text"
                  >
                    <p>
                      {{
                        'header.help_feedback_menu.give_feedback' | translate
                      }}
                    </p>
                    <small>{{
                      'header.help_feedback_menu.give_feedback_explanation'
                        | translate
                    }}</small>
                  </div>
                </div>
              </div>
            </button>
          }
        </div>

        <div
          fxLayoutAlign="space-between baseline"
          [fxLayoutGap]="2 | dsSpacing"
        >
          @if (isAuthorized) {
            <button
              mat-button
              class="mega-menu-item-wrap"
              data-cy="idea-menu-item"
            >
              <div
                class="mega-menu-item"
                fxLayout="row"
                fxLayoutAlign="space-between baseline"
                (click)="openFreshdeskWidget('idea')"
                [gaClickListener]="
                  googleAnalyticsConstants.header.clickListener
                    .openFreshdeskIdea
                "
              >
                <div fxLayout="row">
                  <mat-icon data-cy="menu-icon">lightbulb</mat-icon>
                  <div
                    fxLayout="column wrap"
                    fxLayoutAlign="flex-start"
                    class="mega-menu-item-text"
                  >
                    <p>
                      {{ 'header.help_feedback_menu.submit_idea' | translate }}
                    </p>
                    <small>{{
                      'header.help_feedback_menu.submit_idea_explanation'
                        | translate
                    }}</small>
                  </div>
                </div>
              </div>
            </button>
          }
          <button
            mat-button
            class="mega-menu-item-wrap"
            data-cy="status-menu-item"
          >
            <div
              class="mega-menu-item"
              fxLayout="row"
              fxLayoutAlign="space-between baseline"
              (click)="navigateToNewTab(this.config.paldeskSupportPath)"
              [gaClickListener]="
                googleAnalyticsConstants.header.clickListener
                  .navigateToPaldeskSupport
              "
            >
              <div fxLayout="row">
                <mat-icon data-cy="menu-icon">traffic</mat-icon>
                <div
                  fxLayout="column wrap"
                  fxLayoutAlign="flex-start"
                  class="mega-menu-item-text"
                >
                  <p>
                    {{ 'header.help_feedback_menu.paldesk_status' | translate }}
                  </p>
                  <small>{{
                    'header.help_feedback_menu.paldesk_status_explanation'
                      | translate
                  }}</small>
                </div>
              </div>
              <mat-icon>open_in_new</mat-icon>
            </div>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
