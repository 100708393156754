import { Injectable } from '@angular/core';
import { UserService } from '@features/auth';
import { ROLES } from '../../roles';
import {
  MarketInputRoutes,
  PermissionDeniedRoute,
  TaskAssignmentRoutes,
} from '../../routes';
import { MarketModelRoleType } from '../generated/model/models';
import { RoutesInformation } from '../models/routes-information';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private userService: UserService) {}

  getRoutesInformation(
    marketModelRoles: MarketModelRoleType[],
    currentProvisioningId: number,
  ): RoutesInformation {
    const hasAdminVisibility = this.userService.hasOneRole([
      ROLES.MARKETRESEARCH_ADMIN,
      ROLES.MARKETRESEARCH_GLOBALREAD,
    ]);
    const marketProvisioningExists = currentProvisioningId > 0;

    let routesInformation: RoutesInformation = {
      homeRoute: PermissionDeniedRoute,
      visibleRoutes: [],
    };

    if (hasAdminVisibility) {
      routesInformation = this.getAdminRoutesInformation(
        marketProvisioningExists,
      );
    } else if (marketProvisioningExists && marketModelRoles.length > 0) {
      marketModelRoles.forEach((r, idx) => {
        let roleRoutesInfo: RoutesInformation | null;

        switch (r) {
          case MarketModelRoleType.regionManager:
            roleRoutesInfo = this.getRegionManagerRoutesInformation();
            break;
          case MarketModelRoleType.coordinator:
            roleRoutesInfo = this.getCoordinatorRoutesInformation();
            break;
          case MarketModelRoleType.salesDirector:
            roleRoutesInfo = this.getSalesDirectorRoutesInformation();
            break;
          case MarketModelRoleType.areaSalesManager:
          case MarketModelRoleType.inputUser:
            roleRoutesInfo = this.getStatusRoleRoutesInformation();
            break;
          case MarketModelRoleType.productLineManager:
            roleRoutesInfo = this.getProductLineManagerRoutesInformation();
            break;
          default:
            roleRoutesInfo = null;
            break;
        }

        if (roleRoutesInfo != null) {
          if (idx === 0) {
            // set homeRoute only from first role
            routesInformation.homeRoute = roleRoutesInfo.homeRoute;
          }

          // set new visible routes
          routesInformation.visibleRoutes = [
            ...routesInformation.visibleRoutes,
            ...roleRoutesInfo.visibleRoutes.filter(
              (route) => !routesInformation.visibleRoutes.includes(route),
            ),
          ];
        }
      });
    }

    routesInformation.visibleRoutes = routesInformation.visibleRoutes.map(
      (url) => `^${url.replace(/\//g, '\\/')}$`,
    );

    return routesInformation;
  }

  private buildPath(...parts: string[]): string {
    return parts.join('/');
  }

  private getAdminRoutesInformation(
    marketProvisioningExists: boolean = false,
  ): RoutesInformation {
    const routesInformation = {
      homeRoute: this.buildPath(
        TaskAssignmentRoutes.root,
        TaskAssignmentRoutes.provisionings,
      ),
      visibleRoutes: [
        this.buildPath(
          TaskAssignmentRoutes.root,
          TaskAssignmentRoutes.provisionings,
        ),
      ],
    };

    if (marketProvisioningExists) {
      routesInformation.visibleRoutes.push(
        this.buildPath(MarketInputRoutes.root, MarketInputRoutes.inputStatus),
        this.buildPath(
          MarketInputRoutes.root,
          MarketInputRoutes.regionProductStatus,
        ),
        this.buildPath(
          TaskAssignmentRoutes.root,
          TaskAssignmentRoutes.coordinators,
        ),
        this.buildPath(
          MarketInputRoutes.root,
          MarketInputRoutes.inputDetail,
          '\\d+',
        ),
        this.buildPath(
          TaskAssignmentRoutes.root,
          TaskAssignmentRoutes.inputAssignment,
        ),
      );
    }

    return routesInformation;
  }

  private getRegionManagerRoutesInformation(): RoutesInformation {
    const generalBasePath = this.buildPath(
      TaskAssignmentRoutes.root,
      TaskAssignmentRoutes.coordinators,
    );
    const visibleRoutes = [
      generalBasePath,
      this.buildPath(MarketInputRoutes.root, MarketInputRoutes.inputStatus),
      this.buildPath(
        MarketInputRoutes.root,
        MarketInputRoutes.regionProductStatus,
      ),
      this.buildPath(
        MarketInputRoutes.root,
        MarketInputRoutes.inputDetail,
        '\\d+',
      ),
    ];

    return {
      homeRoute: generalBasePath,
      visibleRoutes: visibleRoutes,
    };
  }

  private getCoordinatorRoutesInformation(): RoutesInformation {
    const generalBasePath = this.buildPath(
      TaskAssignmentRoutes.root,
      TaskAssignmentRoutes.inputAssignment,
    );
    const visibleRoutes = [
      generalBasePath,
      this.buildPath(MarketInputRoutes.root, MarketInputRoutes.inputStatus),
      this.buildPath(
        MarketInputRoutes.root,
        MarketInputRoutes.regionProductStatus,
      ),
      this.buildPath(
        MarketInputRoutes.root,
        MarketInputRoutes.inputDetail,
        '\\d+',
      ),
    ];

    // return general basePath (when app was called initially)
    return {
      homeRoute: generalBasePath,
      visibleRoutes: visibleRoutes,
    };
  }

  private getSalesDirectorRoutesInformation(): RoutesInformation {
    const statusRoleRoutesInformation = this.getStatusRoleRoutesInformation();

    const visibleRoutes = [
      ...statusRoleRoutesInformation.visibleRoutes,
      this.buildPath(
        MarketInputRoutes.root,
        MarketInputRoutes.regionProductStatus,
      ),
    ];

    return {
      homeRoute: statusRoleRoutesInformation.homeRoute,
      visibleRoutes: visibleRoutes,
    };
  }

  // called for marketmodel-roles 'SalesDirector', 'AreaSalesManager' and 'InputUser'
  private getStatusRoleRoutesInformation(): RoutesInformation {
    const generalBasePath = this.buildPath(
      MarketInputRoutes.root,
      MarketInputRoutes.inputStatus,
    );
    const visibleRoutes = [
      generalBasePath,
      this.buildPath(
        MarketInputRoutes.root,
        MarketInputRoutes.inputDetail,
        '\\d+',
      ),
    ];

    // return general basePath (when app was called initially)
    return {
      homeRoute: generalBasePath,
      visibleRoutes: visibleRoutes,
    };
  }

  private getProductLineManagerRoutesInformation(): RoutesInformation {
    const generalBasePath = this.buildPath(
      MarketInputRoutes.root,
      MarketInputRoutes.regionProductStatus,
    );
    const visibleRoutes = [generalBasePath];

    // return general basePath (when app was called initially)
    return {
      homeRoute: generalBasePath,
      visibleRoutes: visibleRoutes,
    };
  }
}
