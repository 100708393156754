import { LoadingSpinnerComponent } from './loading-spinner.component';
import { Injectable, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
/**
 * Service to dispatch a loading spinner in an overlay
 */
@Injectable()
export class LoadingSpinner {
  protected overlayRef: OverlayRef | null;
  constructor(private overlay: Overlay) {}

  /**
   * Shows the loading spinner
   */
  show(viewContainerRef?: ViewContainerRef) {
    if (this.overlayRef == null || !this.overlayRef.hasAttached()) {
      this.overlayRef = this.createOverlay();
      this.attachLoadingSpinnerComponent(this.overlayRef, viewContainerRef);
    }
  }

  /**
   * Dismisses the currently-visible loading spinner
   */
  hide(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  /**
   * Attaches the loading spinner component to the overlay.
   */
  private attachLoadingSpinnerComponent(
    overlayRef: OverlayRef,
    viewContainerRef?: ViewContainerRef,
  ) {
    overlayRef.attach(
      new ComponentPortal(LoadingSpinnerComponent, viewContainerRef),
    );
  }

  /**
   * Creates a new overlay and places it in the correct location.
   */
  private createOverlay(): OverlayRef {
    const state = new OverlayConfig();
    state.hasBackdrop = true;
    state.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    return this.overlay.create(state);
  }
}
