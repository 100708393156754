import { InjectionToken } from '@angular/core';

export interface DsAppWrapperConfig {
  apiBasePath: string;
  paldeskBasePath: string;
  appInsightsRole?: string;
  paldeskSupportPath?: string;
  paldeskDataProtectionRegulationUrl?: string;
  appGatewayBasePath: string;
  isNativeApp?: boolean;
  appName: string;
  appNewsPath?: string;
  appHelpPath?: string;
  environment: string;
  anonymousUserSupportedLanguageCodes?: string[]; //If not provided, a default list of supported languages will be used
  statusBannerProductsToCheck: string[]; //for example ['Business Partner Management']
}

export const DS_APP_WRAPPER_CONFIG = new InjectionToken<DsAppWrapperConfig>(
  'DS_APP_WRAPPER_CONFIG',
);
