<div id="apps-menu-wrapper" data-cy="apps-menu-wrapper">
  <mat-card (click)="$event.stopPropagation()">
    <mat-card-content>
      <div class="apps-menu-category-wrap">
        <ds-new-paldesk-open-app-in-new-tab></ds-new-paldesk-open-app-in-new-tab>
      </div>
      @for (
        category of applications()?.categories | orderCategories;
        track category
      ) {
        <ng-container>
          @if (category) {
            <div class="apps-menu-category-wrap">
              <h4 class="section-header-background">
                {{
                  'applications_menu.' + category.name | translate | uppercase
                }}
              </h4>
              <mat-action-list dense>
                @for (appsInRow of category.rows; track appsInRow) {
                  <div>
                    <div fxLayout="row">
                      <ng-container *ngFor="let app of appsInRow">
                        <div class="category-container">
                          @if (app?.id) {
                            <mat-list-item>
                              <div fxLayout="row">
                                <a
                                  fxLayout="row"
                                  fxLayoutAlign="start start"
                                  fxFlex="1 1 auto"
                                  class="no-underline no-icon"
                                  href="{{ app?.url }}"
                                  target="{{
                                    toggleOpenInNewTabSignal() === true
                                      ? '_blank'
                                      : ''
                                  }}"
                                  (click)="
                                    toggleOpenInNewTabSignal() === true
                                      ? service.closeOverlay()
                                      : ''
                                  "
                                  [gaCategoryGroup]="
                                    googleAnalyticsConstants.header.headerName
                                  "
                                  [gaClickListener]="
                                    googleAnalyticsConstants.header
                                      .clickListener.navigateTo + app?.name
                                  "
                                  data-cy="app-link"
                                >
                                  <div
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    [fxLayoutGap]="1 | dsSpacing"
                                  >
                                    <div fxLayout="column">
                                      <ng-container>
                                        <div class="image-icon">
                                          <img
                                            class="full-width"
                                            src="{{ app?.icon }}"
                                            alt="{{ app?.name }}"
                                            dsAppDefaultImage="assets/img/product-icons/000_default_icon.png"
                                          />
                                        </div>
                                      </ng-container>
                                    </div>
                                    <div fxLayout="column">
                                      @if (app) {
                                        @if (
                                          'bpm_apps.applications.' +
                                            app?.app_id +
                                            '.description'
                                            | translate
                                            | removePlaceholderDescriptions
                                        ) {
                                          <h4 class="app-name">
                                            {{ app.name | translate }}
                                          </h4>
                                          <p
                                            class="app-description"
                                            [attr.data-cy]="
                                              'app-description-' + app.id
                                            "
                                          >
                                            {{
                                              'bpm_apps.applications.' +
                                                app.app_id +
                                                '.description' | translate
                                            }}
                                          </p>
                                        } @else {
                                          <h4 class="app-name no-description">
                                            {{ app.name | translate }}
                                          </h4>
                                        }
                                      }
                                    </div>
                                  </div>
                                </a>
                                <div fxLayout="column" fxLayoutAlign="center">
                                  @if (!app?.isUpdatingFavorite) {
                                    <mat-icon
                                      fxFlexAlign="center"
                                      (click)="toggleFavorite(app)"
                                      >{{
                                        app?.favorite ? 'star' : 'star_outline'
                                      }}
                                    </mat-icon>
                                  } @else {
                                    @if (app?.isUpdatingFavorite) {
                                      <mat-progress-spinner
                                        [mode]="'indeterminate'"
                                        [diameter]="20"
                                      >
                                      </mat-progress-spinner>
                                    }
                                  }
                                </div>
                              </div>
                            </mat-list-item>
                          }
                        </div>
                      </ng-container>
                    </div>
                  </div>
                }
              </mat-action-list>
            </div>
          }
        </ng-container>
      }
    </mat-card-content>
  </mat-card>
</div>
