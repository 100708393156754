<h1 mat-dialog-title>{{ 'general.roles_removed_title' | translate }}</h1>
<mat-dialog-content>
  <p>
    <ds-text-indicator [type]="'warning'">{{
      'general.roles_removed' | translate
    }}</ds-text-indicator>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">
    {{ 'general.cancel' | translate }}
  </button>
  <button mat-raised-button color="accent" cdkFocusInitial (click)="reload()">
    {{ 'general.reload' | translate }}
  </button>
</mat-dialog-actions>
