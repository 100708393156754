import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsBannerModule } from '@design-system/components/banner';
import {
  DsTextIndicatorModule,
  DsTextIndicatorType,
} from '@design-system/components/text-indicator';
import {
  AppWrapperV2Store,
  ConfigService,
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { TranslateModule } from '@ngx-translate/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { PollingUtils } from '@shared-lib/rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ServiceStatusResponse } from './models';

@Component({
  imports: [
    CommonModule,
    DsBannerModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatCardModule,
    DsTextIndicatorModule,
  ],
  selector: 'ds-paldesk-hc-status-banner',
  templateUrl: './hc-status-banner.component.html',
  styleUrls: ['./hc-status-banner.component.scss'],
  standalone: true,
})
export class HcStatusBannerComponent implements OnInit {
  isHideBannerEnabled$: BehaviorSubject<boolean>;
  statusPaldeskPageLink: string;
  type: DsTextIndicatorType = 'warning';

  private readonly paldeskCoreHcApp = 'PALDESK Core';
  constructor(
    private httpClient: HttpClient,
    @Inject(DS_APP_WRAPPER_CONFIG)
    private config: DsAppWrapperConfig,
    private configService: ConfigService,
    private messengerService: MessengerService,
    public store: AppWrapperV2Store,
    private _destroyRef: DestroyRef,
  ) {
    this.isHideBannerEnabled$ = new BehaviorSubject<boolean>(
      sessionStorage.getItem('paldesk_banner_hidden') === 'true',
    );
  }

  ngOnInit(): void {
    if (this.config.statusBannerProductsToCheck.length > 0) {
      const hcCheckRequest$ = this.httpClient
        .post<ServiceStatusResponse[]>(
          this.configService.getApiGatewayBasePath() + '/status/api/health',
          {
            serviceNames: [
              ...this.config.statusBannerProductsToCheck,
              this.paldeskCoreHcApp,
            ],
          },
        )
        .pipe(take(1));
      this.isHideBannerEnabled$
        .pipe(
          switchMap((isHideBannerEnabled) => {
            if (isHideBannerEnabled) {
              this.store.setBannerHidden();
              return of(false);
            } else {
              return PollingUtils.polling(hcCheckRequest$, {
                interval: 300000,
              }).pipe(
                map((checks) => checks?.some((x) => x.status !== 'Healthy')),
                tap((value) =>
                  value
                    ? this.store.setBannerVisible()
                    : this.store.setBannerHidden(),
                ),
              );
            }
          }),
          takeUntilDestroyed(this._destroyRef),
        )
        .subscribe();
    } else {
      this.messengerService.sendMessage(
        MessageSeverityType.error,
        'Health Check Banner component not setup',
        MessageTargetType.console,
      );
    }
  }

  hideBanner(): void {
    this.isHideBannerEnabled$.next(true);
    sessionStorage.setItem('paldesk_banner_hidden', 'true');
    this.store.setBannerHidden();
  }
}
