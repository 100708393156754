import { InjectionToken } from '@angular/core';

// work around for https://github.com/angular/angular-cli/issues/8097
export const SECURITY_TOKEN_SERVICE = new InjectionToken<string>(
  'SECURITY_TOKEN_SERVICE',
);

export const OIDC_CLIENT_ID = new InjectionToken<string>('OIDC_CLIENT_ID');
export const OIDC_SCOPE = new InjectionToken<string>('OIDC_SCOPE');
export const POST_LOGOUT_REDIRECT_URI = new InjectionToken<string>(
  'POST_LOGOUT_REDIRECT_URI',
);
export const IS_MOBILE_APP = new InjectionToken<boolean>('IS_MOBILE_APP');

export const AuthTokens = {
  securityTokenService: SECURITY_TOKEN_SERVICE,
  oidcClientId: OIDC_CLIENT_ID,
  oidcScope: OIDC_SCOPE,
  postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
  isMobileApp: IS_MOBILE_APP,
};
