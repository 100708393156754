import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { EXPANSION_PANEL_ANIMATION_TIMING } from '@angular/material/expansion';
import { dsConfig } from '@design-system/cdk/config';

const dsAnimationTiming = '400ms cubic-bezier(0.25, 0, 0.25, 1)';

export const dsAnimations: {
  readonly fade: AnimationTriggerMetadata;
  readonly fadeIn: AnimationTriggerMetadata;
  readonly fadeOut: AnimationTriggerMetadata;
  readonly slideIn: AnimationTriggerMetadata;
  readonly slideOut: AnimationTriggerMetadata;
  readonly stepTransition: AnimationTriggerMetadata;
  readonly autoHeightExpansion: AnimationTriggerMetadata;
  readonly collapseFilter: AnimationTriggerMetadata;
  readonly rotate180: AnimationTriggerMetadata;
  readonly rotate90: AnimationTriggerMetadata;
} = {
  fade: trigger('dsFade', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(dsAnimationTiming, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(dsAnimationTiming, style({ opacity: 0 })),
    ]),
  ]),

  fadeIn: trigger('dsFadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(dsAnimationTiming, style({ opacity: 1 })),
    ]),
  ]),

  fadeOut: trigger('dsFadeOut', [
    transition(':leave', [
      style({ opacity: 1 }),
      animate(dsAnimationTiming, style({ opacity: 0 })),
    ]),
  ]),

  slideIn: trigger('dsSlideIn', [
    transition(':enter', [
      // eslint-disable-next-line sonarjs/no-duplicate-string
      style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden' }),
      animate(
        dsAnimationTiming,
        style({ transform: 'none', visibility: 'visible' }),
      ),
    ]),
  ]),

  slideOut: trigger('dsSlideOut', [
    transition(':leave', [
      style({ transform: 'none', visibility: 'visible' }),
      animate(
        dsAnimationTiming,
        style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden' }),
      ),
    ]),
  ]),

  stepTransition: trigger('dsStepTransition', [
    transition('* => previous', [
      style({ transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden' }),
      animate(
        dsAnimationTiming,
        style({ transform: 'none', visibility: 'visible' }),
      ),
    ]),
    transition('* => next', [
      style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden' }),
      animate(
        dsAnimationTiming,
        style({ transform: 'none', visibility: 'visible' }),
      ),
    ]),
  ]),

  autoHeightExpansion: trigger('dsAutoHeightExpansion', [
    state('collapsed, void', style({ height: '100%' })),
    state('expanded', style({ height: '*' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate(EXPANSION_PANEL_ANIMATION_TIMING),
    ),
  ]),

  collapseFilter: trigger('dsCollapseFilter', [
    state('collapsed, void', style({ height: 4.25 * dsConfig.spacing + 'px' })),
    state('expanded', style({ height: '*' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate(EXPANSION_PANEL_ANIMATION_TIMING),
    ),
  ]),

  rotate180: trigger('rotate180', [
    state('default', style({ transform: 'rotate(0)' })),
    state('rotated', style({ transform: 'rotate(-180deg)' })),
    transition('default <=> rotated', animate('300ms ease-out')),
  ]),
  rotate90: trigger('rotate90', [
    state('default', style({ transform: 'rotate(0)' })),
    state('rotated', style({ transform: 'rotate(90deg)' })),
    transition('default <=> rotated', animate('300ms ease-out')),
  ]),
};
