import { createAction, props } from '@ngrx/store';
import { DsSnackbarType } from '../snackbar.models';
import { TypedAction } from '@ngrx/store/src/models';

export namespace SnackbarActions {
  export const ShowInfoSnackbar = createAction(
    '[SNACKBAR] SHOW_INFO_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowWarningSnackbar = createAction(
    '[SNACKBAR] SHOW_WARNING_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowSuccessSnackbar = createAction(
    '[SNACKBAR] SHOW_SUCCESS_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowErrorSnackbar = createAction(
    '[SNACKBAR] SHOW_ERROR_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowErrorReloadSnackbar = createAction(
    '[SNACKBAR] SHOW_ERROR_RELOAD_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowNavigationSnackbar = createAction(
    '[SNACKBAR] SHOW_NAVIGATION_SNACKBAR',
    props<{
      messageKey: string;
      actionKey: string;
      navigation: any;
      snackbarType: DsSnackbarType;
    }>(),
  );

  export const NewActionSnackbar = createAction(
    '[SNACKBAR] NEW_ACTION_SNACKBAR',
    props<{
      messageKey: string;
      actionKey: string;
      action: TypedAction<any>;
      snackbarType: DsSnackbarType;
    }>(),
  );
}
