import { Injectable } from '@angular/core';
import { GuidUtils } from '@utils/guid-utils';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageSeverityType } from './models/message-severity.type';
import { MessageTargetType } from './models/message-target.type';
import { MessageUserFilter } from './models/message-user-filter.type';
import { IMessage, IMessageFilter } from './models/message.interface';
import { Utils } from './utils/utils';

@Injectable({
  providedIn: 'root',
})
export class MessengerService {
  trigger$: ReplaySubject<IMessage<any>>;

  constructor() {
    this.trigger$ = new ReplaySubject<IMessage<any>>(5);
  }

  get(): Observable<IMessage<any>> {
    return this.trigger$;
  }

  sendDetailMessage<T>(msg: IMessage<T>) {
    if (!msg.time) {
      msg.time = new Date();
    }

    if (!msg.clientMessageID) {
      msg.clientMessageID = GuidUtils.newGuid();
    }

    this.trigger$.next(msg);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendMessage<T>(
    severity: MessageSeverityType,
    message: string,
    targets: MessageTargetType | MessageTargetType[],
  ) {
    this.sendDetailMessage({
      severity: severity,
      message: message,
      targets: targets,
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public of<T>(messageFilter: IMessageFilter<T>): Observable<IMessage<any>> {
    return this.trigger$.pipe(
      filter((m) => {
        if (messageFilter.severity && m.severity !== messageFilter.severity) {
          return false;
        }

        if (
          Utils.intersectEnums(m.targets, messageFilter.targets).length === 0
        ) {
          return false;
        }

        if (messageFilter.source && m.source !== messageFilter.source) {
          return false;
        }

        if (messageFilter.tags) {
          if (!m.tags) {
            return false;
          }

          if (
            Utils.Intersect_safe(
              Utils.ensureArray<string>(m.tags),
              Utils.ensureArray<string>(messageFilter.tags),
            ).length === 0
          ) {
            return false;
          }
        }

        if (
          messageFilter.userFilter &&
          m.userFilter &&
          !this.matchUserFilter(m.userFilter, messageFilter.userFilter)
        ) {
          return false;
        }

        return true;
      }),
    );
  }

  matchUserFilter(
    triggerUserFilter: MessageUserFilter,
    filterUserFilter: MessageUserFilter,
  ): boolean {
    if (
      filterUserFilter.userName &&
      triggerUserFilter.userName &&
      filterUserFilter.userName === triggerUserFilter.userName
    ) {
      return true;
    }

    if (
      filterUserFilter.accountID &&
      triggerUserFilter.accountID &&
      filterUserFilter.accountID === triggerUserFilter.accountID
    ) {
      return true;
    }

    if (
      filterUserFilter.email &&
      triggerUserFilter.email &&
      filterUserFilter.email === triggerUserFilter.email
    ) {
      return true;
    }

    if (
      filterUserFilter.groupName &&
      triggerUserFilter.groupName &&
      filterUserFilter.groupName === triggerUserFilter.groupName
    ) {
      return true;
    }

    if (
      filterUserFilter.customIdentificator &&
      triggerUserFilter.customIdentificator &&
      filterUserFilter.customIdentificator ===
        triggerUserFilter.customIdentificator
    ) {
      return true;
    }

    return false;
  }
}
