import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsPageModule } from '@design-system/feature/page';
import { HelpPageComponent } from './help-page/help-page.component';

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [
    {
      path: '',
      component: HelpPageComponent,
    },
  ],
);

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    PdLetDirectiveModule,
    MatCardModule,
    MatProgressBarModule,
    DsSpacingModule,
    DsPageModule,
    DsPlaceholderModule,
    routing,
  ],
  declarations: [HelpPageComponent],
})
export class DSHelpPageModule {}
