import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ITranslationResource {
  prefix: string;
  suffix: string;
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: ITranslationResource[],
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const cacheBustingSuffix = `?timestamp=${Date.now()}`;
    const requests = this.resources.map((resource) =>
      this.http
        .get(resource.prefix + lang + resource.suffix + cacheBustingSuffix)
        .pipe(catchError(() => of({}))),
    );
    return forkJoin(requests).pipe(
      map((response) => Object.assign({}, ...response)),
    );
  }
}
