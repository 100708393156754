<div class="survey-wrapper">
  <mat-card>
    <mat-card-header>
      <button
        mat-icon-button
        (click)="closed.emit()"
        gaCategoryGroup="app_wrapper_v2_header"
        gaClickListener="close_survey"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button></mat-card-header
    >
    <mat-card-content>
      <iframe class="survey" [src]="data | surveyUrl | pdSafe"></iframe>
    </mat-card-content>
  </mat-card>
</div>
