import { Component, Input, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexModule } from '@angular/flex-layout';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';
import { AppWrapperV2Store } from '../../signal-store/app-wrapper-v2-signal-store';
import { gAAppWrapperV2Constants } from '../model';

@Component({
  selector: 'ds-new-paldesk-open-app-in-new-tab',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatSlideToggleModule,
    TranslateModule,
    DsSpacingModule,
    FlexModule,
  ],
  templateUrl: './open-app-in-new-tab.component.html',
  styleUrl: './open-app-in-new-tab.component.scss',
})
export class OpenAppInNewTabComponent {
  @Input()
  isSidebar? = false;
  toggleOpenInNewTabSignal: Signal<boolean | null>;

  toggleOpenInNewTab: FormControl<boolean | null> = new FormControl(null);

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  constructor(
    private _store: AppWrapperV2Store,
    private _ga4Service: GoogleAnalytics4Service,
  ) {
    this.toggleOpenInNewTabSignal = this._store.appsNewTabUserSetting;
    this.toggleOpenInNewTab = new FormControl(
      this.toggleOpenInNewTabSignal() ?? true,
    );

    this.toggleOpenInNewTab.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        if (value !== null) {
          this._ga4Service.event(
            this.isSidebar
              ? this.googleAnalyticsConstants.sidebar.clickListener
                  .openAppInNewTabToggle
              : this.googleAnalyticsConstants.header.clickListener
                  .openAppInNewTabToggle,
            {
              category: this.isSidebar
                ? this.googleAnalyticsConstants.sidebar.sidebarName
                : this.googleAnalyticsConstants.header.headerName,
              value: value,
            },
          );
          _store.updateOpenAppsInNewTabUserSetting(value);
        }
      });
  }
}
