import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHttpInterceptor } from './auth.interceptor';
import { AuthOptions, IDENTITYSERVER_AUTH_CONFIG_TOKEN } from './models';

@Injectable({
  providedIn: 'root',
})
export class IdentityServerAuthInterceptor extends AuthHttpInterceptor {
  constructor(@Inject(IDENTITYSERVER_AUTH_CONFIG_TOKEN) config: AuthOptions) {
    super(config);
  }

  override intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return super.intercept(request, next);
  }
}
