import {
  ContentChildren,
  Directive,
  Input,
  QueryList,
  forwardRef,
} from '@angular/core';

import { GoogleAnalyticsClickListenerDirective } from './click-listener.directive';

@Directive({
  selector: '[gaLabelGroup]',
  standalone: true,
})
export class GoogleAnalyticsLabelGroupDirective {
  @ContentChildren(forwardRef(() => GoogleAnalyticsLabelGroupDirective), {
    descendants: true,
  })
  childLabel: QueryList<GoogleAnalyticsLabelGroupDirective>;

  @ContentChildren(GoogleAnalyticsClickListenerDirective, {
    descendants: true,
  })
  set children(values: QueryList<GoogleAnalyticsClickListenerDirective>) {
    if (!this.childLabel?.length)
      values.forEach((child) => {
        child.parentLabel = this.gaLabelGroup;
      });
  }

  @Input() gaLabelGroup: string;
}
