<div class="button-icon" fxLayout="row">
  <input
    class="search-input"
    fxFlex
    enterkeyhint="search"
    [formControl]="searchFormControl"
    [matAutocomplete]="suggestionsMenu"
    data-cy="search-input"
    disableRipple
    placeholder="{{ 'header.search.placeholder' | translate }}"
    (click)="getSuggestions()"
    (keydown.enter)="doSearch()"
    #suggestionsMenuTrigger="matAutocompleteTrigger"
  />
  <mat-autocomplete #suggestionsMenu="matAutocomplete" [disableRipple]="true">
    @if (history().length) {
      <mat-optgroup label="{{ 'header.search.recent_searches' | translate }}">
        @for (historyTerm of history(); track historyTerm; let first = $first) {
          @if (historyTerm.hint && historyTerm.search_term) {
            <div
              matRipple
              fxLayout="row"
              fxLayoutAlign="start center"
              class="history entry"
              [class.margin-top]="first"
              [attr.data-cy]="historyTerm.search_term + '-suggestion'"
              [gaClickListener]="
                googleAnalyticsConstants.header.clickListener.searchHistory
              "
              (click)="historySearch(historyTerm)"
            >
              <mat-option
                class="full-width no-padding-left"
                [value]="historyTerm"
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>schedule</mat-icon>
                  <ds-new-paldesk-suggestion
                    class="cut-text"
                    [suggestion]="historyTerm"
                  ></ds-new-paldesk-suggestion>
                </div>
              </mat-option>
              @if (media.isActive('lt-lg')) {
                <mat-icon
                  (click)="
                    deleteHistorySearchTerm($event, historyTerm.search_term)
                  "
                  [gaClickListener]="
                    googleAnalyticsConstants.header.clickListener
                      .searchHistoryDelete
                  "
                  >clear</mat-icon
                >
              } @else {
                <a
                  class="remove-link"
                  (click)="
                    deleteHistorySearchTerm($event, historyTerm.search_term)
                  "
                  [gaClickListener]="
                    googleAnalyticsConstants.header.clickListener
                      .searchHistoryDelete
                  "
                  >{{ 'header.search.remove' | translate }}</a
                >
              }
            </div>
          }
        }
      </mat-optgroup>
    }
    @if (suggestions().length) {
      <mat-optgroup
        class="suggestions-group"
        label="{{ 'header.search.new_search_suggestions' | translate }}"
      >
        @for (
          suggestionTerm of suggestions();
          track suggestionTerm;
          let first = $first
        ) {
          @if (suggestionTerm.hint && suggestionTerm.search_term) {
            <div
              (click)="suggestionSearch(suggestionTerm)"
              matRipple
              fxLayout="row"
              fxLayoutAlign="start center"
              class="suggestion entry"
              [attr.data-cy]="suggestionTerm.search_term + '-suggestion'"
              [class.margin-top]="first"
              [gaClickListener]="
                googleAnalyticsConstants.header.clickListener.searchSuggestion
              "
            >
              <mat-option
                [value]="suggestionTerm"
                class="full-width no-padding-left"
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>search</mat-icon>
                  <ds-new-paldesk-suggestion
                    class="cut-text"
                    [suggestion]="suggestionTerm"
                  ></ds-new-paldesk-suggestion>
                  &nbsp;<i class="suggestion-category"
                    >in {{ suggestionTerm.category }}</i
                  >
                </div>
              </mat-option>
            </div>
          }
        }
      </mat-optgroup>
    }
  </mat-autocomplete>

  <button
    mat-raised-button
    class="search-button"
    color="accent"
    (click)="doSearch()"
  >
    <mat-icon class="cursor-pointer no-margin">search </mat-icon>
  </button>
</div>
