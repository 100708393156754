<div
  id="wrapper"
  class="{{ type }}"
  fxLayout
  fxLayoutAlign="space-between center"
  [fxLayoutGap]="0.5 | dsSpacing"
  data-cy="ds-snackbar"
>
  <ds-text-indicator
    matSnackBarLabel
    [type]="type"
    [noColor]="true"
    data-cy="ds-snackbar-message"
    fxFlex="0 1 auto"
    class="cut-text"
    >{{ message }}</ds-text-indicator
  >
  @if (action) {
    @if (href) {
      <a
        matSnackBarAction
        mat-button
        (click)="snackbarRef.dismissWithAction()"
        [href]="href"
        color="white"
        target="_blank"
        data-cy="ds-snackbar-button"
        data-test="snackbar-button"
        fxFlex="0 0 auto"
      >
        {{ action }}
      </a>
    } @else {
      <button
        matSnackBarAction
        mat-button
        color="white"
        (click)="snackbarRef.dismissWithAction()"
        data-cy="ds-snackbar-button"
        data-test="snackbar-button"
        fxFlex="0 0 auto"
      >
        {{ action }}
      </button>
    }
  }
</div>
