import { NgModule } from '@angular/core';
import { MenuComponent } from './menu.component';
import { SharedWrapperModule } from '../../shared/shared-wrapper.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [SharedWrapperModule],
  exports: [MenuComponent],
})
export class MenuModule {}
