import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegionList, RootState } from './root.reducer';

export namespace RootSelectors {
  export const rootState = createFeatureSelector<RootState>('root');

  export const showLoadingSpinner = createSelector(
    rootState,
    (state) => state.showLoadingSpinner,
  );
  export const loadingSpinnerValue = createSelector(
    rootState,
    (state) => state.loadingSpinnerValue,
  );

  export const countries = createSelector(
    rootState,
    (state) => state.countries,
  );

  export const countryList = createSelector(rootState, (state) =>
    state.countries ? state.countries.countryList : undefined,
  );

  export const loadingCountries = createSelector(
    rootState,
    (state) => state.loadingCountries,
  );

  export const regions = createSelector(
    rootState,
    (state: RootState, props: any) =>
      state.regions?.find(
        (regionList: RegionList) =>
          regionList.language === props.language &&
          regionList.country === props.country,
      ),
  );

  export const regionsError = createSelector(
    rootState,
    (state) => state.regionsError,
  );

  export const bannerState = createSelector(
    rootState,
    (state) => state.bannerState,
  );
}
