import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showClearButton',
})
export class ShowClearButtonPipe implements PipeTransform {
  transform(value: any | undefined | null): boolean {
    return Array.isArray(value)
      ? !!value?.length
      : value === null || value === undefined
        ? false
        : !!String(value).length;
  }
}
