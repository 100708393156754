import { NgModule } from '@angular/core';
import { GoogleAnalyticsCategoryGroupDirective } from './category-group.directive';
import { GoogleAnalyticsClickListenerDirective } from './click-listener.directive';
import { GoogleAnalyticsLabelGroupDirective } from './label-group.directive';

const directives = [
  GoogleAnalyticsLabelGroupDirective,
  GoogleAnalyticsCategoryGroupDirective,
  GoogleAnalyticsClickListenerDirective,
];

@NgModule({
  imports: directives,
  exports: directives,
})
export class GoogleAnalytics4DirectiveModule {}
