<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  fxLayoutAlign="center center"
>
  <img src="assets/svg/icons/no-search-result.svg" />

  <h2 class="no-margin-top">
    {{ 'special_pages.page_not_found' | translate }}
  </h2>

  <p>
    {{ 'special_pages.requested_url_not_found' | translate }} <br />
    {{ 'special_pages.contact_our_team_or_visit_homepage' | translate }}
  </p>

  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    [fxLayoutGap]="0.5 | dsSpacing"
  >
    <a
      mat-stroked-button
      [href]="
        'mailto:' +
        supportMail +
        '?subject=Page%20not520found%20-%2Support%20request'
      "
      >{{ 'special_pages.request_support' | translate }}</a
    >
    <a mat-button mat-stroked-button [href]="homeAdress">
      {{ 'special_pages.go_home' | translate }}
    </a>
  </div>
</div>
