import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Injector,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AzureApiManagementInterceptor } from './azure-api-management.interceptor';
import { IdentityServerAuthInterceptor } from './identityserver.auth.interceptor';
import { AuthOptions, IDENTITYSERVER_AUTH_CONFIG_TOKEN } from './models';
import { HttpInterceptorTokens } from './tokens';
import { UserService } from '@features/auth';

export function identityServerAuthOptionsFactory(
  oauthService: OAuthService,
  injector: Injector,
  identityServerAuthConf: string,
): AuthOptions {
  const authOptions = identityServerAuthConf as AuthOptions;
  if (oauthService) {
    authOptions.tokenGetter = () => {
      const token = oauthService.getAccessToken();
      const shouldRefreshToken =
        token &&
        Date.now() > oauthService.getAccessTokenExpiration() - 1000 * 60;
      return shouldRefreshToken
        ? // The UserService is injected here to avoid a circular dependency
          injector
            .get(UserService)
            .tryRefreshSession()
            .then(() => oauthService.getAccessToken())
        : token;
    };
    authOptions.tokenValidator = () => oauthService.hasValidAccessToken();
    authOptions.tokenEndpointGetter = () => oauthService.tokenEndpoint;
  }
  return authOptions;
}

export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AzureApiManagementInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: IdentityServerAuthInterceptor,
    multi: true,
  },
];

const AuthConfigTokens = [
  {
    provide: IDENTITYSERVER_AUTH_CONFIG_TOKEN,
    useFactory: identityServerAuthOptionsFactory,
    deps: [
      [new Optional(), OAuthService],
      Injector,
      HttpInterceptorTokens.identityServerAuthConf,
    ],
  },
];

@NgModule()
export class HttpInterceptorModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: HttpInterceptorModule,
  ) {
    if (parentModule) {
      throw new Error(
        "HttpInterceptorModule is already loaded. It should only be imported in your application's main module.",
      );
    }
  }

  static forRoot(
    apiManagementOptionsProvider: Provider,
  ): ModuleWithProviders<HttpInterceptorModule> {
    return {
      ngModule: HttpInterceptorModule,
      providers: [
        apiManagementOptionsProvider,
        AuthConfigTokens,
        HttpInterceptorProviders,
      ],
    };
  }
}
