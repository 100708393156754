import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { RootActions, RootState } from '@features/root-store';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorClassificatorService } from './error-classificator.service';
import { HttpInterceptorTokens } from './tokens';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private _errorClassificatorService: ErrorClassificatorService;

  private get errorClassificatorService(): ErrorClassificatorService {
    if (!this._errorClassificatorService) {
      this._errorClassificatorService = this.injector.get(
        ErrorClassificatorService,
      );
    }

    return this._errorClassificatorService;
  }

  constructor(
    private injector: Injector,
    private store: Store<RootState>,
    @Optional()
    @Inject(HttpInterceptorTokens.errorHandlingRoutes)
    private errorHandlingRoutes: string[],
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        // if API has generic error handling
        if (
          this.errorHandlingRoutes &&
          this.errorHandlingRoutes.some((r) => new RegExp(r).test(error.url))
        ) {
          this.store.dispatch(
            RootActions.ShowErrorMessage({ payload: error.statusText }),
          );
        } else {
          this.errorClassificatorService.processError(error, req);
        }
        return throwError(error);
      }),
    );
  }
}
