<div fxLayout="row" [fxLayoutGap]="0.5 | dsSpacing" fxLayoutAlign="start end">
  <span>{{ 'header.apps.open_app' | translate }}</span>
  <mat-slide-toggle
    [formControl]="toggleOpenInNewTab"
    [checked]="toggleOpenInNewTabSignal()"
    data-cy="open-in-new-tab-toggle"
    >{{
      (toggleOpenInNewTabSignal()
        ? 'header.apps.new_tab'
        : 'header.apps.current_tab'
      ) | translate
    }}
  </mat-slide-toggle>
</div>
