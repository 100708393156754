import {
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { GoogleAnalytics4Service } from './analytics.service';

export const GoogleAnalytics4MeasurementId = new InjectionToken(
  'GA_4_MEASUREMENT_ID',
);

@NgModule({
  imports: [CommonModule],
})
export class GoogleAnalytics4Module {
  constructor(@Optional() @SkipSelf() parentModule?: GoogleAnalytics4Module) {
    if (parentModule) {
      throw new Error(
        'GoogleAnalytics4Module is already loaded. Import it in your AppModule only',
      );
    }
  }

  static forRoot(
    measurementIdProvider: Provider,
  ): ModuleWithProviders<GoogleAnalytics4Module> {
    return {
      ngModule: GoogleAnalytics4Module,
      providers: [GoogleAnalytics4Service, measurementIdProvider],
    };
  }
}
