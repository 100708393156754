<div fxLayout="column" fxFlexFill [fxLayoutGap]="1 | dsSpacing">
  <ui-kit-loading-spinner
    *ngIf="loading$ | async"
    class="loading-spinner"
  ></ui-kit-loading-spinner>
  <ng-container *ngIf="(loading$ | async) === false">
    <h2>{{ 'market_model.landing.title' | translate }}</h2>
    <ds-text-indicator>
      {{ 'market_model.landing.insufficient_permissions' | translate }}
    </ds-text-indicator>
  </ng-container>
</div>
