import {
  ContentChildren,
  Directive,
  Input,
  QueryList,
  forwardRef,
} from '@angular/core';

import { GoogleAnalyticsClickListenerDirective } from './click-listener.directive';

@Directive({
  selector: '[gaCategoryGroup]',
  standalone: true,
})
export class GoogleAnalyticsCategoryGroupDirective {
  @ContentChildren(forwardRef(() => GoogleAnalyticsCategoryGroupDirective), {
    descendants: true,
  })
  childGroup: QueryList<GoogleAnalyticsCategoryGroupDirective>;

  @ContentChildren(GoogleAnalyticsClickListenerDirective, {
    descendants: true,
  })
  set children(values: QueryList<GoogleAnalyticsClickListenerDirective>) {
    if (!this.childGroup.length)
      values.forEach((child) => {
        child.parentCategory = this.gaCategoryGroup;
      });
  }

  @Input() gaCategoryGroup: string;
}
