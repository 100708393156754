import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiManagementOptions, API_MANAGEMENT_CONFIG_TOKEN } from './models';

@Injectable()
export class AzureApiManagementInterceptor implements HttpInterceptor {
  headerName: string;
  subscriptionKey: string;
  whitelistedDomains: Array<string>;
  constructor(
    @Inject(API_MANAGEMENT_CONFIG_TOKEN) config: ApiManagementOptions,
  ) {
    this.headerName = config.headerName || 'Ocp-Apim-Subscription-Key';
    this.whitelistedDomains = config.whitelistedDomains || [];
    this.subscriptionKey = config.subscriptionKey || '';
  }

  isWhitelistedDomain(request: HttpRequest<any>): boolean {
    let requestUrl: URL;
    try {
      requestUrl = new URL(request.url);
      if (requestUrl.hostname === 'localhost') {
        return true;
      }
      return this.whitelistedDomains.indexOf(requestUrl.host) > -1;
    } catch (err) {
      // if we're here, the request is made
      // to the same domain as the Angular app
      // so it's safe to proceed
      return true;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.subscriptionKey && this.isWhitelistedDomain(request)) {
      request = request.clone({
        setHeaders: {
          [this.headerName]: `${this.subscriptionKey}`,
        },
      });
    }
    return next.handle(request);
  }
}
