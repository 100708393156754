import { Injectable } from '@angular/core';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ROLES } from '../../roles';
import { RootActions, RootState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private userService: UserService,
    private rootStore: Store<RootState>,
  ) {}

  public syncUserDataWithStore() {
    this.userService.currentUser.pipe(filter((x) => !!x)).subscribe(() => {
      this.storeUserData();
      this.loadCurrentProvisioningData();
    });
  }

  private storeUserData() {
    const hasGlobalReadOnly =
      this.userService.hasRole(ROLES.MARKETRESEARCH_GLOBALREAD) &&
      !this.userService.hasRole(ROLES.MARKETRESEARCH_ADMIN);

    this.rootStore.dispatch(
      RootActions.SetGlobalReadOnly({ payload: hasGlobalReadOnly }),
    );
  }

  private loadCurrentProvisioningData() {
    this.rootStore.dispatch(RootActions.LoadCurrentProvisioningId());
    this.rootStore.dispatch(RootActions.LoadMarketModelRoles());
    this.rootStore.dispatch(RootActions.GetRoutesInformation());
    this.rootStore.dispatch(RootActions.LoadUserSettings());
  }
}
