import { Component, Inject, Optional } from '@angular/core';
import {
  DS_SPECIAL_PAGE_HOME_URL,
  DS_SPECIAL_PAGE_SUPPORT_MAIL,
} from '../variables';

@Component({
  selector: 'ds-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['../shared.scss', './page-not-found.component.scss'],
})
export class DsPageNotFoundComponent {
  homeAdress = 'https://paldesk.palfinger.com/';
  supportMail = 'paldesk@palfinger.com';

  constructor(
    @Optional() @Inject(DS_SPECIAL_PAGE_HOME_URL) homeAdress: string,
    @Optional() @Inject(DS_SPECIAL_PAGE_SUPPORT_MAIL) supportMail: string,
  ) {
    if (homeAdress) {
      this.homeAdress = homeAdress;
    }
    if (supportMail) {
      this.supportMail = supportMail;
    }
  }
}
