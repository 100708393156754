<div
  *pdLet="userService.isAuthorized$ | async as isAuthorized"
  id="user-menu-wrapper"
  [gaCategoryGroup]="googleAnalyticsConstants.header.headerName"
>
  <mat-card (click)="$event.stopPropagation()" id="userOverlay">
    <mat-card-content fxLayout="column">
      @if (isAuthorized) {
        <div fxLayout="row" class="icon-content">
          <mat-icon>account_circle</mat-icon>
          <div fxLayout="column">
            <p data-cy="user-profile-name">
              {{ headerData?.data?.company_name }}
            </p>
            <p data-cy="user-profile-company-name">
              {{ headerData?.data?.firstname }} {{ headerData?.data?.lastname }}
              <span
                *ngIf="
                  headerData?.data?.partnertype !== partnerTypes.END_CUSTOMER
                "
                data-cy="user-profile-username"
                >({{ headerData?.data?.username }})</span
              >
            </p>
            <p data-cy="user-profile-email">{{ headerData?.data?.email }}</p>
          </div>
        </div>
        <div class="background-user-menu">
          <h4 class="palfinger-font">
            {{ 'header.user_menu.account' | translate | uppercase }}
          </h4>
        </div>
        <button mat-button data-cy="user-profile-btn">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between baseline"
            (click)="navigateToProfile()"
            [gaClickListener]="
              googleAnalyticsConstants.header.clickListener
                .navigateToIdentityProfile
            "
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>person_outline</mat-icon>
              <p>{{ 'header.user_menu.lb_user_profile' | translate }}</p>
            </div>
            <mat-icon>open_in_new</mat-icon>
          </div>
        </button>
        <button mat-button data-cy="pat-btn">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between baseline"
            (click)="navigateToPat()"
            [gaClickListener]="
              googleAnalyticsConstants.header.clickListener.navigateToPat
            "
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>security</mat-icon>
              <p>{{ 'header.user_menu.pat' | translate }}</p>
            </div>
            <mat-icon>open_in_new</mat-icon>
          </div>
        </button>
      } @else {
        <button mat-button data-cy="sign-in-btn">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between baseline"
            (click)="login()"
            [gaClickListener]="
              googleAnalyticsConstants.header.clickListener
                .navigateToIdentityProfile
            "
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>login</mat-icon>
              <p>{{ 'general.sign_in' | translate }}</p>
            </div>
          </div>
        </button>
      }

      <div class="background-user-menu">
        <h4 class="palfinger-font">
          {{ 'header.user_menu.legal_statement' | translate | uppercase }}
        </h4>
      </div>
      <button
        data-cy="privacy-btn"
        mat-button
        (click)="openPrivacyPolicy()"
        [gaClickListener]="
          googleAnalyticsConstants.header.clickListener.openPrivacy
        "
      >
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>{{ 'header.user_menu.privacy_policy' | translate }}</p>
          <mat-icon>open_in_new</mat-icon>
        </div>
      </button>
      <button
        data-cy="terms-conditions-btn"
        mat-button
        (click)="openLinkTermsConditions()"
        [gaClickListener]="
          googleAnalyticsConstants.header.clickListener.openTos
        "
      >
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>{{ 'general.terms_and_conditions' | translate }}</p>
          <mat-icon>open_in_new</mat-icon>
        </div>
      </button>
      <mat-divider> </mat-divider>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button
          data-cy="sign-out-btn"
          [class.anonymous-user]="!isAuthorized"
          mat-button
          (click)="logout()"
          [gaClickListener]="
            googleAnalyticsConstants.header.clickListener.logout
          "
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>logout</mat-icon>
            <p>{{ 'general.sign_out' | translate }}</p>
          </div>
        </button>
        <p>
          {{ 'header.user_menu.copyright' | translate: { year: currentYear } }}
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
