import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTextIndicatorComponent } from './text-indicator.component';

@NgModule({
  imports: [CommonModule, MatIconModule, FlexLayoutModule, DsSpacingModule],
  exports: [DsTextIndicatorComponent],
  declarations: [DsTextIndicatorComponent],
})
export class DsTextIndicatorModule {}
