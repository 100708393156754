import { MatSnackBarRef as MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, ReplaySubject } from 'rxjs';
import { DsSnackbarComponent } from './snackbar.component';

export class DsSnackbarOptions {
  type?: DsSnackbarType;
  action?: string;
  duration?: number;
  href?: string;
}

export enum DsSnackbarType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export class DsSnackbarData extends DsSnackbarOptions {
  message: string;
  ref: Observable<MatSnackBarRef<DsSnackbarComponent>>;
}

export class DsSnackbarQueueItem {
  message: string;
  options: DsSnackbarOptions;
  ref: ReplaySubject<MatSnackBarRef<DsSnackbarComponent>>;
}
