import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { AuthGuard, AuthPermissions } from '@features/auth';
import { environment } from '../environments/environment';
import { LandingComponent } from './landing/landing.component';
import {
  MarketInputRoutes,
  PermissionDeniedRoute,
  TaskAssignmentRoutes,
} from './routes';
import { MarketModelRouteGuard } from './shared/guards/market-model-route.guard';

const defaultRoute = 'landing';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [
      (next: ActivatedRouteSnapshot) =>
        inject(AuthGuard).canActivateChild(next),
    ],
    data: {
      permissions: { roles: environment.roles } as AuthPermissions,
      breadcrumb: 'market_model.default',
    },
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
      {
        path: 'landing',
        component: LandingComponent,
      },
      {
        path: TaskAssignmentRoutes.root,
        loadChildren: () =>
          import('./_task-assignment/task-assignment.module').then(
            (m) => m.TaskAssignmentModule,
          ),
        canActivate: [MarketModelRouteGuard],
        data: {
          breadcrumb: 'task_assignment.default',
        },
      },
      {
        path: MarketInputRoutes.root,
        loadChildren: () =>
          import('./_market-input/market-input.module').then(
            (m) => m.MarketInputModule,
          ),
        canActivate: [MarketModelRouteGuard],
        data: {
          breadcrumb: 'market_input.default',
        },
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: PermissionDeniedRoute,
    redirectTo: defaultRoute,
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
