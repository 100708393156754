import { DsSnackbarType } from '@design-system/feature/snackbar';
import { createAction, props } from '@ngrx/store';
import { LoadingStatus } from '@ui-kit/loading-status';
import {
  CountryOemDto,
  ProductDto,
  RegionDto,
  UserDto,
  UserSettingsDto,
} from '../generated';
import { MarketModelRoleDto } from '../generated/model/marketModelRoleDto';
import { PartnerDto } from '../generated/model/partnerDto';
import { RoutesInformation } from '../models/routes-information';

export namespace RootActions {
  export const LoadCurrentProvisioningId = createAction(
    '[Root] LOAD_CURRENT_PROVISIONING_ID',
  );
  export const LoadCurrentProvisioningIdSuccess = createAction(
    '[Root] LOAD_CURRENT_PROVISIONING_ID_SUCCESS',
    props<{ payload: number }>(),
  );
  export const LoadCurrentProvisioningIdError = createAction(
    '[Root] LOAD_CURRENT_PROVISIONING_ID_ERROR',
    props<{ payload: LoadingStatus }>(),
  );

  export const LoadMarketModelRoles = createAction(
    '[Root] LOAD_MARKET_MODEL_ROLES',
  );
  export const LoadMarketModelRolesSuccess = createAction(
    '[Root] LOAD_MARKET_MODEL_ROLES_SUCCESS',
    props<{ payload: MarketModelRoleDto[] }>(),
  );
  export const LoadMarketModelRolesError = createAction(
    '[Root] LOAD_MARKET_MODEL_ROLES_ERROR',
    props<{ payload: LoadingStatus }>(),
  );

  export const GetRoutesInformation = createAction(
    '[Root] GET_ROUTES_INFORMATION',
  );
  export const GetRoutesInformationSuccess = createAction(
    '[Root] GET_ROUTES_INFORMATION_SUCCESS',
    props<{ payload: RoutesInformation }>(),
  );

  export const SetGlobalReadOnly = createAction(
    '[Root] SET_GLOBAL_READ_ONLY',
    props<{ payload: boolean }>(),
  );

  export const LoadPartners = createAction('[Root] LOAD_PARTNERS');

  export const LoadPartnersSuccess = createAction(
    '[Root] LOAD_PARTNERS_SUCCESS',
    props<{ payload: PartnerDto[] }>(),
  );
  export const LoadPartnersError = createAction(
    '[Root] LOAD_PARTNERS_ERROR',
    props<{ payload: LoadingStatus }>(),
  );

  export const LoadUsers = createAction(
    '[Root] LOAD_USERS',
    props<{ payload: number }>(),
  );
  export const LoadUsersSuccess = createAction(
    '[Root] LOAD_USERS_SUCCESS',
    props<{ payload: UserDto[] }>(),
  );
  export const LoadUsersError = createAction(
    '[Root] LOAD_USERS_ERROR',
    props<{ payload: LoadingStatus }>(),
  );
  export const ShowSnackbarTranslated = createAction(
    '[Root] SHOW_SNACKBAR_TRANSLATED',
    props<{
      key: string;
      // eslint-disable-next-line @typescript-eslint/ban-types
      interPolateParams?: Object;
      snackbarType: DsSnackbarType;
    }>(),
  );
  export const ShowSnackbar = createAction(
    '[Root] SHOW_SNACKBAR',
    props<{
      message: string;
      snackbarType: DsSnackbarType;
    }>(),
  );

  export const SaveFile = createAction(
    '[Root] SAVE_FILE',
    props<{
      data: string | Blob;
      filename?: string;
    }>(),
  );

  export const LoadRegions = createAction('[Root] LOAD_REGIONS');
  export const LoadRegionsSuccess = createAction(
    '[Root] LOAD_REGIONS_SUCCESS',
    props<{ payload: RegionDto[] }>(),
  );
  export const LoadRegionsError = createAction(
    '[Root] LOAD_REGIONS_ERROR',
    props<{ payload: LoadingStatus }>(),
  );

  export const LoadProducts = createAction('[Root] LOAD_PRODUCTS');
  export const LoadProductsSuccess = createAction(
    '[Root] LOAD_PRODUCTS_SUCCESS',
    props<{ payload: ProductDto[] }>(),
  );
  export const LoadProductsError = createAction(
    '[Root] LOAD_PRODUCTS_ERROR',
    props<{ payload: LoadingStatus }>(),
  );

  export const LoadCountriesAndOems = createAction(
    '[Root] LOAD_COUNTRIES_AND_OEMS',
  );
  export const LoadCountriesAndOemsSuccess = createAction(
    '[Root] LOAD_COUNTRIES_AND_OEMS_SUCCESS',
    props<{ payload: CountryOemDto[] }>(),
  );
  export const LoadCountriesAndOemsError = createAction(
    '[Root] LOAD_COUNTRIES_AND_OEMS_ERROR',
    props<{ payload: LoadingStatus }>(),
  );

  export const LoadUserSettings = createAction('[Root] Load user settings');
  export const LoadUserSettingsSuccess = createAction(
    '[Root] Load user settings success',
    props<{ payload: UserSettingsDto }>(),
  );
  export const LoadUserSettingsError = createAction(
    '[Root] Load user settings failure',
    props<{ payload: LoadingStatus }>(),
  );

  export const UpdateUserSettingsPartial = createAction(
    '[Root] Update user settings partial',
    props<{ payload: Partial<UserSettingsDto> }>(),
  );
  export const UpdateUserSettings = createAction(
    '[Root] Update user settings',
    props<{ payload: UserSettingsDto }>(),
  );
  export const UpdateUserSettingsSuccess = createAction(
    '[Root] Update user settings success',
    props<{ payload: UserSettingsDto }>(),
  );
  export const UpdateUserSettingsError = createAction(
    '[Root] Update user settings error',
    props<{ payload: LoadingStatus }>(),
  );

  export const ClearCache = createAction('[Root] Clear cache');
  export const ClearCacheSuccess = createAction(
    '[Root] Clear cache successful',
  );
  export const ClearCacheFailed = createAction(
    '[Root] Clear cache failed',
    props<{ error: any }>(),
  );
}
