import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DsSpacingPipe } from './spacing.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DsSpacingPipe],
  exports: [DsSpacingPipe],
})
export class DsSpacingModule {}
