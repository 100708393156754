import { OAuthStorage } from 'angular-oauth2-oidc';

/**
 * Custom storage used for storing the tokens at client side.
 * Values which need to survive browser redirects are stored
 * in the session storage, all other values are stored in memory.
 */
export class OAuthCustomStorage implements OAuthStorage {
  private data = new Map<string, string>();
  private sessionStorageKeys = ['PKCE_verifier', 'nonce'];

  getItem(key: string): string | null {
    return this.useSessionStorage(key)
      ? sessionStorage.getItem(key)
      : this.data.get(key) || null;
  }
  removeItem(key: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.useSessionStorage(key)
      ? sessionStorage.removeItem(key)
      : this.data.delete(key);
  }
  setItem(key: string, data: string): void {
    if (this.useSessionStorage(key)) {
      sessionStorage.setItem(key, data);
    } else {
      this.data.set(key, data);
    }
  }

  private useSessionStorage(key: string): boolean {
    return this.sessionStorageKeys.includes(key);
  }
}
