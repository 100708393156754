import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'gridRowColumnStyle',
})
export class GridRowColumnStylePipe implements PipeTransform {
  transform(items: number): any {
    let columns: number;
    let rows: number;
    if (items <= 8) {
      columns = 1;
      rows = items;
    } else if (items >= 9 && items <= 15) {
      columns = 2;
      rows = Math.ceil(items / columns);
    } else {
      columns = 3;
      rows = Math.ceil(items / columns);
    }

    return {
      gridTemplateColumns: Array.from({ length: columns }, () => '1fr').join(
        ' ',
      ),
      gridTemplateRows: Array.from({ length: rows }, () => '1fr').join(' '),
    };
  }
}
