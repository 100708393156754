import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuard } from './guard/guard';
import { RolesRemovedDialogComponent } from './roles-removed-dialog/roles-removed-dialog.component';
import { OAuthCustomStorage } from './services';
import { AuthTokens } from './shared-feat-auth.tokens';

interface SharedFeatAuthModuleConfig {
  isMobileApp: boolean;
}

/**
 * Provides all necessary stuff for using clean authentication.
 * Should be only imported in the ROOT MODULE
 */
@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    OAuthModule.forRoot(),
    TranslateModule,
    DsTextIndicatorModule,
  ],
  declarations: [RolesRemovedDialogComponent],
})
export class SharedFeatAuthModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SharedFeatAuthModule,
  ) {
    if (parentModule) {
      throw new Error(
        'PaldeskAuthModule is already loaded. Import it in the AppModule only!',
      );
    }
  }

  static forRoot(
    config: SharedFeatAuthModuleConfig = { isMobileApp: false },
  ): ModuleWithProviders<SharedFeatAuthModule> {
    return {
      ngModule: SharedFeatAuthModule,
      providers: [
        AuthGuard,
        OAuthService,
        config.isMobileApp
          ? { provide: OAuthStorage, useValue: localStorage }
          : { provide: OAuthStorage, useClass: OAuthCustomStorage },
        {
          provide: AuthTokens.isMobileApp,
          useValue: config.isMobileApp,
        },
      ],
    };
  }
}
