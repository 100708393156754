import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DsPageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DsPermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { DsSignoutComponent } from './signout/signout.component';
import { DsSpecialPagesComponent } from './special-pages.component';

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [
    {
      path: '',
      component: DsSpecialPagesComponent,
      children: [
        {
          path: 'pagenotfound',
          component: DsPageNotFoundComponent,
          data: {
            breadcrumb: 'Page not found',
          },
        },
        {
          path: 'permissiondenied',
          component: DsPermissionDeniedComponent,
          data: {
            breadcrumb: 'Access denied',
            hideMenu: true,
          },
        },
        {
          path: 'signout',
          component: DsSignoutComponent,
          data: {
            breadcrumb: 'Sign out',
          },
        },
        { path: '**', redirectTo: 'pagenotfound' },
      ],
    },
  ],
);
