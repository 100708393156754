import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DsSnackbarType } from '@design-system/feature/snackbar';
import { Store } from '@ngrx/store';
import { RootActions, RootState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor(private rootStore: Store<RootState>) {}

  showError(response: HttpErrorResponse) {
    if (response.status === 400 && typeof response.error === 'string') {
      this.rootStore.dispatch(
        RootActions.ShowSnackbar({
          snackbarType: DsSnackbarType.Error,
          message: response.error,
        }),
      );
    } else {
      this.rootStore.dispatch(
        RootActions.ShowSnackbarTranslated({
          snackbarType: DsSnackbarType.Error,
          key: 'market_model.snackbar_messages.unknown_error',
        }),
      );
    }
  }
}
