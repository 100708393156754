<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  fxLayoutAlign="center center"
>
  <h2 class="no-margin-top">
    {{ 'special_pages.session_expired' | translate }}
  </h2>

  <button mat-stroked-button (click)="relogin()">
    {{ 'special_pages.session_expired_action' | translate }}
  </button>
</div>
