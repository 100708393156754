import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';

export const FEATURE_FLAG_GUARD = new InjectionToken<any>('FEATURE_FLAGS');

/**
 * Implements General Route Protection by checking if a feature is activated or not
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  private featureFlags;
  constructor(
    public router: Router,
    @Inject(FEATURE_FLAG_GUARD) featureFlags: string,
  ) {
    this.featureFlags = featureFlags;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.isFeatureEnabled(route);
  }
  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.isFeatureEnabled(route);
  }
  canMatch(route: Route): boolean {
    return this.isFeatureEnabled(route);
  }

  private isFeatureEnabled(route: Route | ActivatedRouteSnapshot): boolean {
    const enabled =
      route && route.data
        ? this.featureFlags[route.data['feature_flag']] === 'true'
        : false;
    if (!enabled) {
      const redirectUrl = route.data
        ? this.featureFlags[route.data['feature_flag_redirect_url']]
        : '';
      if (redirectUrl) {
        this.navigateToExternalUrl(redirectUrl);
      } else {
        this.redirectToPermissionDeniedPage();
      }
    }
    return enabled;
  }

  navigateToExternalUrl(url: string): void {
    window.location.href = url;
  }

  private redirectToPermissionDeniedPage(): void {
    this.router.navigate(['/special/permissiondenied']);
  }
}
