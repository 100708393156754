<div class="container" fxLayout="column">
  <mat-form-field>
    <mat-label>{{ 'forms.btn_search' | translate }}</mat-label>
    <input matInput type="text" [formControl]="filter" />
    <button matSuffix mat-icon-button (click)="filter.reset()">
      <mat-icon>{{ filter.value ? 'close' : 'search' }}</mat-icon>
    </button>
  </mat-form-field>
  <ds-new-paldesk-open-app-in-new-tab></ds-new-paldesk-open-app-in-new-tab>
  @for (
    appCategory of appCategoriesFiltered() | orderCategories;
    track appCategory
  ) {
    <ng-container>
      <h4>
        {{ appCategory.name | translate | uppercase }}
      </h4>
      <div class="applications-container">
        @for (app of appCategory.applications; track app) {
          <div class="app-item">
            <div fxLayout="row">
              <a
                fxFlex
                fxLayout="row"
                class="no-underline no-icon align-center"
                href="{{ app.url }}"
                target="{{
                  toggleOpenInNewTabSignal() === true ? '_blank' : ''
                }}"
                [gaCategoryGroup]="googleAnalyticsConstants.sidebar.sidebarName"
                [gaClickListener]="
                  googleAnalyticsConstants.sidebar.clickListener.navigateTo +
                  app.name
                "
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  [fxLayoutGap]="1 | dsSpacing"
                >
                  <div fxLayout="column">
                    @if (app.icon) {
                      <ng-container>
                        @if (!app.icon?.includes('http')) {
                          <ng-container>
                            <mat-icon class="app-icon">{{ app.icon }}</mat-icon>
                          </ng-container>
                        } @else {
                          <div class="image-icon">
                            <img
                              class="full-width"
                              src="{{ app.icon }}"
                              alt="{{ app.name }}"
                              dsAppDefaultImage="assets/img/product-icons/000_default_icon.png"
                            />
                          </div>
                        }
                      </ng-container>
                    } @else {
                      {{ app.name | translate | appShortName }}
                    }
                  </div>
                  <div fxLayout="column">
                    @if (
                      'bpm_apps.applications.' + app.app_id + '.description'
                        | translate
                        | removePlaceholderDescriptions
                    ) {
                      <h4 class="app-name">
                        {{ app.name | translate }}
                      </h4>
                      <p
                        class="app-description"
                        [attr.data-cy]="'app-description-' + app.id"
                      >
                        {{
                          'bpm_apps.applications.' + app.app_id + '.description'
                            | translate
                        }}
                      </p>
                    } @else {
                      <h4 class="app-name no-description">
                        {{ app.name | translate }}
                      </h4>
                    }
                  </div>
                </div>
              </a>
              <div
                fxLayout="column"
                fxLayoutAlign="start"
                class="star-container"
              >
                @if (!app.isUpdatingFavorite) {
                  <mat-icon
                    class="star"
                    inlineblock
                    (click)="toggleFavorite(app)"
                    >{{ app.favorite ? 'star' : 'star_outline' }}
                  </mat-icon>
                } @else {
                  @if (app.isUpdatingFavorite) {
                    <mat-progress-spinner
                      [mode]="'indeterminate'"
                      [diameter]="20"
                    >
                    </mat-progress-spinner>
                  }
                }
              </div>
            </div>
          </div>
        }
      </div>
    </ng-container>
  }
  @if (!appCategoriesFiltered()?.length) {
    <div>
      {{ 'header.apps.no_apps_found' | translate }}
    </div>
  }
</div>
