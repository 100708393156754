import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { DsPageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DsPermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { DsSignoutComponent } from './signout/signout.component';
import { DsSpecialPagesComponent } from './special-pages.component';
import { routing } from './special-pages.routing';

@NgModule({
  imports: [
    CommonModule,
    routing,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    DsSpacingModule,
  ],
  declarations: [
    DsSpecialPagesComponent,
    DsPageNotFoundComponent,
    DsPermissionDeniedComponent,
    DsSignoutComponent,
  ],
})
export class DsSpecialPagesModule {}
