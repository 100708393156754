// KEEP THIS FILE IN SYNC WITH colors.scss
export interface DsColorMap {
  10?: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
}

export interface DsColorPalette extends DsColorMap {
  contrast: DsColorMap;
}

export interface DsColors {
  primary: DsColorPalette;
  success: DsColorPalette;
  error: DsColorPalette;
  warning: DsColorPalette;
  info: DsColorPalette;
  neutral: DsColorPalette;
}

export const colors: DsColors = {
  primary: {
    50: '#f9e2e3',
    100: '#efb7ba',
    200: '#e4888c',
    300: '#d9585e',
    400: '#d1343c',
    500: '#c91019',
    600: '#c30e16',
    700: '#bc0c12',
    800: '#b5090e',
    900: '#a90508',
    A100: '#ffd4d4',
    A200: '#ffa1a1',
    A400: '#ff6e6f',
    A700: '#ff5456',
    contrast: {
      50: '#707070',
      100: '#707070',
      200: '#707070',
      300: '#ffffff',
      400: '#ffffff',
      500: '#ffffff',
      600: '#ffffff',
      700: '#ffffff',
      800: '#ffffff',
      900: '#ffffff',
      A100: '#707070',
      A200: '#707070',
      A400: '#ffffff',
      A700: '#ffffff',
    },
  },
  success: {
    50: '#e4f5e9',
    100: '#bce5c8',
    200: '#8fd4a4',
    300: '#62c27f',
    400: '#41b563',
    500: '#1fa848',
    600: '#1ba041',
    700: '#179738',
    800: '#128d30',
    900: '#0a7d21',
    A100: '#adffba',
    A200: '#7aff8f',
    A400: '#47ff64',
    A700: '#2dff4e',
    contrast: {
      50: '#707070',
      100: '#707070',
      200: '#707070',
      300: '#ffffff',
      400: '#ffffff',
      500: '#ffffff',
      600: '#ffffff',
      700: '#ffffff',
      800: '#ffffff',
      900: '#ffffff',
      A100: '#707070',
      A200: '#707070',
      A400: '#ffffff',
      A700: '#ffffff',
    },
  },

  warning: {
    50: '#fceee0',
    100: '#f8d5b3',
    200: '#f3b980',
    300: '#ee9d4d',
    400: '#ea8826',
    500: '#e67300',
    600: '#e36b00',
    700: '#df6000',
    800: '#db5600',
    900: '#d54300',
    A100: '#fffdfc',
    A200: '#ffd7c9',
    A400: '#ffb096',
    A700: '#ff9d7d',
    contrast: {
      50: '#707070',
      100: '#707070',
      200: '#707070',
      300: '#ffffff',
      400: '#ffffff',
      500: '#ffffff',
      600: '#ffffff',
      700: '#ffffff',
      800: '#ffffff',
      900: '#ffffff',
      A100: '#707070',
      A200: '#707070',
      A400: '#ffffff',
      A700: '#ffffff',
    },
  },

  error: {
    50: '#ffe7e9',
    100: '#ffc4c8',
    200: '#ff9da4',
    300: '#ff7680',
    400: '#ff5864',
    500: '#ff3b49',
    600: '#ff3542',
    700: '#ff2d39',
    800: '#ff2631',
    900: '#ff1921',
    A100: '#ffffff',
    A200: '#fffdfd',
    A400: '#ffcacc',
    A700: '#ffb1b3',
    contrast: {
      50: '#707070',
      100: '#707070',
      200: '#707070',
      300: '#ffffff',
      400: '#ffffff',
      500: '#ffffff',
      600: '#ffffff',
      700: '#ffffff',
      800: '#ffffff',
      900: '#ffffff',
      A100: '#707070',
      A200: '#707070',
      A400: '#ffffff',
      A700: '#ffffff',
    },
  },

  info: {
    50: '#e4eefc',
    100: '#bad5f8',
    200: '#8db9f4',
    300: '#5f9df0',
    400: '#3c88ec',
    500: '#1a73e9',
    600: '#176be6',
    700: '#1360e3',
    800: '#0f56df',
    900: '#0843d9',
    A100: '#ffffff',
    A200: '#cfdaff',
    A400: '#9cb3ff',
    A700: '#83a0ff',
    contrast: {
      50: '#707070',
      100: '#707070',
      200: '#707070',
      300: '#ffffff',
      400: '#ffffff',
      500: '#ffffff',
      600: '#ffffff',
      700: '#ffffff',
      800: '#ffffff',
      900: '#ffffff',
      A100: '#707070',
      A200: '#707070',
      A400: '#ffffff',
      A700: '#ffffff',
    },
  },

  neutral: {
    10: '#f8f9fb',
    50: '#ecedee',
    100: '#ced2d5',
    200: '#aeb5b9',
    300: '#8e979c',
    400: '#758087',
    500: '#5d6a72',
    600: '#55626a',
    700: '#4b575f',
    800: '#414d55',
    900: '#303c42',
    A100: '#93d9ff',
    A200: '#60c7ff',
    A400: '#2db5ff',
    A700: '#14acff',
    contrast: {
      50: '#707070',
      100: '#707070',
      200: '#707070',
      300: '#ffffff',
      400: '#ffffff',
      500: '#ffffff',
      600: '#ffffff',
      700: '#ffffff',
      800: '#ffffff',
      900: '#ffffff',
      A100: '#707070',
      A200: '#707070',
      A400: '#ffffff',
      A700: '#ffffff',
    },
  },
};
