import { computed } from '@angular/core';
import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';
export type CallState = 'init' | 'loading' | 'loaded' | { error: string };

export interface CallStateSlice {
  callState: CallState;
}

export const initCallStateSlice: CallStateSlice = {
  callState: 'init',
};

export function withCallState() {
  return signalStoreFeature(
    withState(initCallStateSlice),
    withComputed((store) => ({
      loading: computed(() => store.callState() === 'loading'),
      loaded: computed(() => store.callState() === 'loaded'),
      error: computed(() => {
        const value = store.callState();
        if (typeof value === 'object') {
          return value.error;
        }
        return null;
      }),
    })),
  );
}

export function setLoading(): CallStateSlice {
  return { callState: 'loading' };
}

export function setLoaded(): CallStateSlice {
  return { callState: 'loaded' };
}

export function setError(error: string): CallStateSlice {
  return { callState: { error: error } };
}
