import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removePlaceholderDescriptions',
  standalone: true,
})
export class RemovePlaceholderDescriptions implements PipeTransform {
  transform(description: string) {
    return description === 'to_be_replaced_by_product_owner' ? '' : description;
  }
}
