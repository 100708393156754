import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dsAppDefaultImage]',
  standalone: true,
})
export class DsDefaultImageDirective {
  @Input() dsAppDefaultImage: string;

  constructor(private el: ElementRef<HTMLImageElement>) {}

  @HostListener('error')
  loadImageError(): void {
    this.el.nativeElement.src = this.dsAppDefaultImage;
  }
}
