import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinner } from './loading-spinner';
/**
 * NOTE: Will be removed after migration
 * Consumed by: Market Model, NDS, Sales Lib
 */
@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [LoadingSpinnerComponent],
  exports: [LoadingSpinnerComponent],
  providers: [LoadingSpinner],
})
export class LoadingSpinnerModule {}
