<div
  class="container"
  fxLayout="column"
  [gaCategoryGroup]="googleAnalyticsConstants.sidebar.sidebarName"
  [gaLabelGroup]="documentType"
>
  <mat-form-field>
    <mat-label>{{ 'forms.btn_search' | translate }}</mat-label>
    <input matInput type="text" [formControl]="filter" />
    <button matSuffix mat-icon-button (click)="filter.reset()">
      <mat-icon>{{ filter.value ? 'close' : 'search' }}</mat-icon>
    </button>
  </mat-form-field>
  <div *ngFor="let documentCategory of documentsFiltered()">
    <button
      class="btn cursor-pointer"
      mat-button
      (click)="
        appWrapperService.openInNewTab(documentCategory.code, documentType)
      "
      [gaClickListener]="
        googleAnalyticsConstants.sidebar.clickListener.navigateToDocument +
        documentCategory.code
      "
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        [fxLayoutGap]="0.5 | dsSpacing"
      >
        <img
          class="img"
          [src]="
            documentCategory.icon ||
            'https://static-test.paldesk.palfinger.com/palipedia/images/icons/Service-3623cf55-3f3c-4ff8-9133-b8fd710edc95.jpg  '
          "
        />
        <span>{{ documentCategory.name }}</span>
      </div>
    </button>
  </div>
  <div *ngIf="!documentsFiltered()?.length">
    {{
      'header.documents.no_documents_found'
        | translate: { documentType: documentType }
    }}
  </div>
</div>
