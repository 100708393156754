import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from '@features/auth';
import { BehaviorSubject, Observable } from 'rxjs';

declare global {
  interface Window {
    OnetrustActiveGroups: any;
  }
}

export interface CookieConsent {
  performanceCookies: boolean;
}

export interface ICookieConsentService {
  get cookieConsent(): CookieConsent;
  get cookieConsent$(): Observable<CookieConsent>;
}

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService implements ICookieConsentService {
  private PERFORMANCE_COOKIE_NAME = 'C0002';
  private ONE_TRUST_COOKIE_UPDATE_EVENT_NAME = 'OneTrustGroupsUpdated';
  private ONE_TRUST_COOKIE_CONSENT_SCRIPT_ID = 'OneTrustCookieConsent';
  private ONE_TRUST_CONSENT_SDK = 'onetrust-consent-sdk';
  private consent$: BehaviorSubject<CookieConsent>;

  get cookieConsent$(): Observable<CookieConsent> {
    return this.consent$.asObservable();
  }

  get cookieConsent(): CookieConsent {
    return this.consent$.value;
  }

  constructor(private _userService: UserService) {
    this.consent$ = new BehaviorSubject<CookieConsent>({
      performanceCookies: false,
    });

    this._userService.isAuthorized$.pipe(takeUntilDestroyed()).subscribe({
      next: (authorized) => {
        if (!authorized) {
          this.addConsentScript();
          this.consent$.next({
            performanceCookies:
              window.OnetrustActiveGroups?.includes(
                this.PERFORMANCE_COOKIE_NAME,
              ) ?? false,
          });
        } else {
          document
            .getElementById(this.ONE_TRUST_COOKIE_CONSENT_SCRIPT_ID)
            ?.remove();
          document.getElementById(this.ONE_TRUST_CONSENT_SDK)?.remove();
          this.consent$.next({
            performanceCookies: true,
          });
        }
      },
    });

    window.addEventListener(
      this.ONE_TRUST_COOKIE_UPDATE_EVENT_NAME,
      ($event: any) => {
        this.consent$.next({
          performanceCookies: $event.detail.includes(
            this.PERFORMANCE_COOKIE_NAME,
          ),
        });
      },
    );
  }

  setConsent(flag: boolean): void {
    this.consent$.next({
      performanceCookies: flag,
    });
  }

  private addConsentScript() {
    const script = document.createElement('script');
    script.id = this.ONE_TRUST_COOKIE_CONSENT_SCRIPT_ID;
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute(
      'data-domain-script',
      '0192307e-7eb6-764c-be8a-f0efada6295b',
    );
    script.setAttribute('data-ignore-ga', 'true');
    document.head.insertBefore(script, document.head.firstChild);
  }
}
