import { InjectionToken } from '@angular/core';

export const APPLICATION_INSIGHTS_KEY = new InjectionToken<string>(
  'APPLICATION_INSIGHTS_KEY',
);

// 0 - verbose
// 1 - Information
// 2 - Warning
// 3 - Error
// 4 - Critical
export const APPLICATION_INSIGHTS_MIN_LOG_LEVEL = new InjectionToken<number>(
  'APPLICATION_INSIGHTS_MIN_LOG_LEVEL',
);
