import { Directive, HostListener, Input } from '@angular/core';

import { GoogleAnalytics4Service } from '../analytics.service';

export interface GaEvent {
  category?: string;
  action: string;
  label?: string;
  value?: string;
}

@Directive({
  selector: '[gaClickListener]',
  standalone: true,
})
export class GoogleAnalyticsClickListenerDirective {
  @Input() gaClickListener: GaEvent | string;
  @Input() parentCategory?: string;
  @Input() parentLabel?: string;

  @HostListener('click')
  public onClick(): void {
    if (typeof this.gaClickListener === 'string') {
      this._ga4service.event(this.gaClickListener, {
        category: this.parentCategory,
        label: this.parentLabel,
      });
    } else {
      this._ga4service.event(this.gaClickListener.action, {
        category: this.gaClickListener.category || this.parentCategory,
        label: this.gaClickListener.label || this.parentLabel,
        value: this.gaClickListener.value,
      });
    }
  }

  constructor(private _ga4service: GoogleAnalytics4Service) {}
}
