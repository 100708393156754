import { DsColors, colors } from './colors';
import { sizes } from './spacing';

export interface DsConfig {
  spacing: number;
  borderRadius: number;
  colors: DsColors;
}

export const dsConfig: DsConfig = {
  spacing: sizes.spacing,
  borderRadius: sizes.borderRadius,
  colors,
};
