import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppShortNamePipe } from './app-short-name.pipe';
/**
 * #129664
 * @deprecated
 * NOTE: Will be moved to app wrapper after migration
 * Consumed by: PALDESK Header, New App Wrapper
 */
@NgModule({
  imports: [CommonModule],
  declarations: [AppShortNamePipe],
  exports: [AppShortNamePipe],
})
export class AppShortNamePipeModule {}
