import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEquipmentIcon',
  standalone: true,
})
export class IsEqupmentIconPipe implements PipeTransform {
  transform(value: string): boolean {
    const regex = /^\d{3}$/;
    return regex.test(value);
  }
}
