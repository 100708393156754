import { Injectable } from '@angular/core';
import {
  MatSnackBar as MatSnackBar,
  MatSnackBarRef as MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Observable, ReplaySubject } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { DsSnackbarComponent } from './snackbar.component';
import {
  DsSnackbarData,
  DsSnackbarOptions,
  DsSnackbarQueueItem,
  DsSnackbarType,
} from './snackbar.models';

@Injectable()
export class DsSnackbarService {
  private snackbarQueue: DsSnackbarQueueItem[] = [];

  constructor(private _snackBar: MatSnackBar) {}

  queue(
    message: string,
    options?: DsSnackbarOptions,
  ): Observable<MatSnackBarRef<DsSnackbarComponent>> {
    const ref = new ReplaySubject<MatSnackBarRef<DsSnackbarComponent>>();
    this.snackbarQueue.push({
      message: message,
      options: options || {},
      ref: ref,
    });

    if (this.snackbarQueue.length === 1) {
      this.openNext();
    }

    return ref.asObservable();
  }

  private openNext() {
    const snackbarData: DsSnackbarData = {
      message: this.snackbarQueue[0].message,
      type: this.snackbarQueue[0].options.type || DsSnackbarType.Info,
      action: this.snackbarQueue[0].options.action,
      ref: this.snackbarQueue[0].ref,
      href: this.snackbarQueue[0].options.href,
    };

    const snackbarRef = this._snackBar.openFromComponent(DsSnackbarComponent, {
      duration:
        this.snackbarQueue[0].options.duration ||
        (this.snackbarQueue[0].options.action ? 8000 : 4000),
      horizontalPosition: 'start',
      panelClass: 'snackbar-with-component',
      data: snackbarData,
    });

    snackbarRef
      .afterDismissed()
      .pipe(take(1), delay(400))
      .subscribe(() => {
        this.snackbarQueue.shift();
        if (this.snackbarQueue.length > 0) {
          this.openNext();
        }
      });

    this.snackbarQueue[0].ref.next(snackbarRef);
  }
}
