import { Action, createReducer, on } from '@ngrx/store';
import { LoadingStatus } from '@ui-kit/loading-status';
import {
  CountryOemDto,
  MarketModelRoleDto,
  ProductDto,
  RegionDto,
  UserDto,
  UserSettingsDto,
} from '../generated';
import { PartnerDto } from '../generated/model/partnerDto';
import { RoutesInformation } from '../models/routes-information';
import { RootActions } from './root.actions';

export interface RootState {
  currentProvisioningId?: number;
  currentProvisioningIdLoading?: LoadingStatus;
  marketModelRoles: MarketModelRoleDto[];
  marketModelRolesLoading?: LoadingStatus;
  routesInformation?: RoutesInformation;
  isGlobalReadOnly: boolean;
  partners: PartnerDto[];
  partnersLoadingStatus: LoadingStatus;
  selectedPartnerId?: number;
  users: UserDto[];
  usersLoadingStatus?: LoadingStatus;
  regions?: RegionDto[];
  regionsLoadingStatus?: LoadingStatus;
  products?: ProductDto[];
  productsLoadingStatus?: LoadingStatus;
  countriesAndOems?: CountryOemDto[];
  countriesAndOemsLoadingStatus?: LoadingStatus;
  userSettings: UserSettingsDto;

  clearCacheStatus?: LoadingStatus;
}

export const initialState: RootState = {
  currentProvisioningId: undefined,
  currentProvisioningIdLoading: undefined,
  marketModelRoles: [],
  marketModelRolesLoading: undefined,
  routesInformation: undefined,
  isGlobalReadOnly: false,
  partners: [],
  partnersLoadingStatus: LoadingStatus.Loading,
  users: [],
  usersLoadingStatus: undefined,
  regions: undefined,
  regionsLoadingStatus: undefined,
  products: undefined,
  productsLoadingStatus: undefined,
  countriesAndOems: undefined,
  countriesAndOemsLoadingStatus: undefined,
  userSettings: {
    dataInputOverviewSettings: {
      displayAreaSalesManager: true,
      displayInputUser: true,
      displayMyStatus: true,
      displayProgress: true,
      displayRegion: true,
      displayState: true,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(RootActions.LoadPartners, (state) => ({
    ...state,
    partnersLoadingStatus: LoadingStatus.Loading,
  })),
  on(RootActions.LoadPartnersSuccess, (state, { payload }) => ({
    ...state,
    partners: payload,
    partnersLoadingStatus: LoadingStatus.Success,
  })),
  on(RootActions.LoadPartnersError, (state, { payload }) => ({
    ...state,
    partnersLoadingStatus: payload,
  })),
  on(RootActions.LoadUsers, (state, { payload }) => ({
    ...state,
    usersLoadingStatus: LoadingStatus.Loading,
    users: [],
    selectedPartnerId: payload,
  })),
  on(RootActions.LoadUsersSuccess, (state, { payload }) => ({
    ...state,
    users: payload,
    usersLoadingStatus: LoadingStatus.Success,
  })),
  on(RootActions.LoadUsersError, (state, { payload }) => ({
    ...state,
    usersLoadingStatus: payload,
  })),
  on(RootActions.LoadCurrentProvisioningId, (state) => ({
    ...state,
    currentProvisioningIdLoading: LoadingStatus.Loading,
  })),
  on(RootActions.LoadCurrentProvisioningIdSuccess, (state, { payload }) => ({
    ...state,
    currentProvisioningId: payload,
    currentProvisioningIdLoading: LoadingStatus.Success,
  })),
  on(RootActions.LoadCurrentProvisioningIdError, (state, { payload }) => ({
    ...state,
    currentProvisioningIdLoading: payload,
  })),
  on(RootActions.LoadMarketModelRoles, (state) => ({
    ...state,
    marketModelRolesLoading: LoadingStatus.Loading,
  })),
  on(RootActions.LoadMarketModelRolesSuccess, (state, { payload }) => ({
    ...state,
    marketModelRoles: payload,
    marketModelRolesLoading: LoadingStatus.Success,
  })),
  on(RootActions.LoadMarketModelRolesError, (state, { payload }) => ({
    ...state,
    marketModelRolesLoading: payload,
  })),
  on(RootActions.GetRoutesInformationSuccess, (state, { payload }) => ({
    ...state,
    routesInformation: payload,
  })),
  on(RootActions.SetGlobalReadOnly, (state, { payload }) => ({
    ...state,
    isGlobalReadOnly: payload,
  })),

  on(RootActions.LoadRegions, (state) => ({
    ...state,
    regionsLoadingStatus: LoadingStatus.Loading,
  })),
  on(RootActions.LoadRegionsSuccess, (state, { payload }) => ({
    ...state,
    regions: payload,
    regionsLoadingStatus: LoadingStatus.Success,
  })),
  on(RootActions.LoadRegionsError, (state, { payload }) => ({
    ...state,
    regionsLoadingStatus: payload,
  })),

  on(RootActions.LoadProducts, (state) => ({
    ...state,
    productsLoadingStatus: LoadingStatus.Loading,
  })),
  on(RootActions.LoadProductsSuccess, (state, { payload }) => ({
    ...state,
    products: payload,
    productsLoadingStatus: LoadingStatus.Success,
  })),
  on(RootActions.LoadProductsError, (state, { payload }) => ({
    ...state,
    productsLoadingStatus: payload,
  })),

  on(RootActions.LoadCountriesAndOems, (state) => ({
    ...state,
    countriesAndOemsLoadingStatus: LoadingStatus.Loading,
  })),
  on(RootActions.LoadCountriesAndOemsSuccess, (state, { payload }) => ({
    ...state,
    countriesAndOems: payload,
    countriesAndOemsLoadingStatus: LoadingStatus.Success,
  })),
  on(RootActions.LoadCountriesAndOemsError, (state, { payload }) => ({
    ...state,
    countriesAndOemsLoadingStatus: payload,
  })),

  on(RootActions.LoadUserSettingsSuccess, (state, { payload }) => {
    if (payload) {
      return {
        ...state,
        userSettings: payload,
      };
    }
    return state;
  }),

  on(RootActions.ClearCache, (state) => ({
    ...state,
    clearCacheStatus: LoadingStatus.Loading,
  })),
  on(RootActions.ClearCacheFailed, (state) => ({
    ...state,
    clearCacheStatus: LoadingStatus.ConnectionError,
  })),
  on(RootActions.ClearCacheSuccess, (state) => ({
    ...state,
    clearCacheStatus: LoadingStatus.Success,
  })),
);
export function rootReducer(state: RootState | undefined, action: Action) {
  return reducer(state, action);
}
