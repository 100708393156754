import { createAction, props } from '@ngrx/store';

export namespace SpotlightActions {
  export const RegisterSpotlight = createAction(
    '[Spotlight] REGISTER_SPOTLIGHT',
    props<{ id: string; tags?: string[] }>(),
  );

  export const UnregisterSpotlight = createAction(
    '[Spotlight] UNREGISTER_SPOTLIGHT',
    props<{ id: string }>(),
  );

  export const SetSpotlightAsUnseen = createAction(
    '[Spotlight] SET_SPOTLIGHT_AS_UNSEEN',
    props<{ id: string }>(),
  );

  export const SetAllSpotlightAsUnseen = createAction(
    '[Spotlight] SET_ALL_SPOTLIGHT_AS_UNSEEN',
    props<{ payload?: string }>(),
  );

  export const SetSpotlightsAsUnseenByTags = createAction(
    '[Spotlight] SET_SPOTLIGHTS_AS_UNSEEN_BY_TAGS',
    props<{ tags: string[] }>(),
  );

  export const OpenNext = createAction('[Spotlight] OPEN_NEXT');

  export const OpenPrevious = createAction('[Spotlight] OPEN_PREVIOUS');

  export const Close = createAction('[Spotlight] CLOSE');
}
