import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SanitizeService {
  constructor(private sanitizer: DomSanitizer) {}

  sanitizeSearchTermHtmlDecode(value: string | null): string | undefined {
    const sanitizedString = this.sanitizer.sanitize(
      SecurityContext.HTML,
      value?.trim() ?? null,
    );

    if (!sanitizedString) {
      return undefined;
    }

    // html decode sanitized string
    const doc = new DOMParser().parseFromString(sanitizedString, 'text/html');
    return doc.documentElement.textContent || undefined;
  }

  sanitizeFilter(values: string[] | null): string[] {
    if (!values) {
      return [];
    }
    return values.map((value) => {
      const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, value);
      return sanitized !== null ? sanitized : '';
    });
  }
}
