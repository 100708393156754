import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule, DsSpacingPipe } from '@design-system/cdk/spacing';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { LoadingSpinnerModule } from '@ui-kit/loading-spinner';
import { BreakProductNamePipe } from './pipes/break-product-name.pipe';
import { FindInArrayPipe } from './pipes/find-in-array.pipe';
import { CloneObjectService } from './services/clone-object.service';

@NgModule({
  imports: [DsSnackbarComponent],
  exports: [
    CommonModule,
    DsSpacingModule,
    DsPageModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatMenuModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    DsTableModule,
    DsPlaceholderModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    DsLoadingModule,
    DsTextIndicatorModule,
    MatDialogModule,
    DsSnackbarComponent,
    DsFilterInputModule,
    FindInArrayPipe,
    PdLetDirectiveModule,
    BreakProductNamePipe,
  ],
  providers: [DsSpacingPipe, CloneObjectService],
  declarations: [FindInArrayPipe, BreakProductNamePipe],
})
export class SharedModule {}
