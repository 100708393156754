import {
  ActionCreator,
  ActionReducer,
  ActionType,
  on,
  ReducerTypes,
} from '@ngrx/store';
import { Draft, produce } from 'immer';

// credits to: https://github.com/timdeschryver/ngrx-immer/blob/main/src/store/index.ts

export function immerReducer<State, Next>(
  callback: (state: State, next: Next) => State | void,
) {
  return (state: State | undefined, next: Next) =>
    produce(state, (draft: State) => callback(draft, next)) as State;
}

export type ImmerOnReducer<State, AC extends ActionCreator[]> = (
  state: Draft<State>,
  action: ActionType<AC[number]>,
) => void;

/**
 * Immer wrapper around `on` to mutate state
 */
export function produceOn<State, Creators extends ActionCreator[]>(
  ...args: [...creators: Creators, reducer: ImmerOnReducer<State, Creators>]
): ReducerTypes<State, Creators> {
  /* eslint-disable @typescript-eslint/ban-types */
  const reducer = args.pop() as Function as ActionReducer<State>;
  return (on as any)(...(args as ActionCreator[]), immerReducer(reducer));
}
