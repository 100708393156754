import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaThemeColorService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setThemeColor(color: string): void {
    let metaTag = this.document.querySelector('meta[name="theme-color"]');
    if (!metaTag) {
      metaTag = this.document.createElement('meta');
      metaTag.setAttribute('name', 'theme-color');
      this.document.head.appendChild(metaTag);
    }
    metaTag.setAttribute('content', color);
  }
}
