<mat-card *pdLet="convertedData$ | async as convertedData">
  <mat-card-content
    *ngIf="(isLoading$ | async) === false; else loadingTemplate"
  >
    <div
      *ngIf="convertedData?.length; else placeholderTemplate"
      fxLayout="row"
      [fxLayoutGap]="1 | dsSpacing"
    >
      <div fxFlex="auto" [innerHTML]="convertedData"></div>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #placeholderTemplate>
  <ds-placeholder type="no_data"></ds-placeholder>
</ng-template>
