import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { setError, setLoaded, setLoading, withCallState } from '@config';
import {
  Application,
  ApplicationCategory,
  ApplicationContainer,
  ApplicationService,
} from '@features/applications';
import { UserService } from '@features/auth';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { UserService as IdentityUserService } from '@paldesk/shared-lib/data-access/identity-service-generated';
import {
  ApiCategory,
  CategoryService,
} from '@paldesk/shared-lib/data-access/palipedia-service-generated';
import { EMPTY, NEVER, catchError, combineLatest, switchMap, tap } from 'rxjs';
import { CalculateViewportData } from '../shared/model';

export interface AppWrapperV2State {
  productCategories: Array<ApiCategory> | null;
  applications: ApplicationContainer | null;
  serviceDocumentCategories: Array<ApiCategory> | null;
  appsNewTabUserSetting: boolean | null;
  bannerVisible: boolean | null;
  isMobileView: boolean | null;
}

const initialState: AppWrapperV2State = {
  productCategories: null,
  applications: null,
  serviceDocumentCategories: null,
  appsNewTabUserSetting: null,
  bannerVisible: null,
  isMobileView: false,
};

@Injectable({
  providedIn: 'root',
})
export class AppWrapperV2Store extends signalStore(
  withState(initialState),
  withCallState(),
  withMethods((state) => {
    const categoryService = inject(CategoryService);
    const userService = inject(UserService);
    const identityUserService = inject(IdentityUserService);
    const applicationService = inject(ApplicationService);
    const destroyRef = inject(DestroyRef);
    return {
      updateHeaderState: rxMethod<CalculateViewportData>((pair) =>
        pair.pipe(
          tap((headerState) => {
            if (
              headerState.media.some((media) => media.mqAlias === 'lt-md') &&
              (!headerState.currentUser ||
                headerState.currentUser.partnertype === 5)
            ) {
              patchState(state, { isMobileView: true });
            } else if (
              headerState.media.some((media) => media.mqAlias === 'lt-lg') &&
              headerState.currentUser &&
              headerState.currentUser.partnertype !== 5
            ) {
              patchState(state, { isMobileView: true });
            } else {
              patchState(state, { isMobileView: false });
            }
          }),
        ),
      ),

      filterApps: (name: string) => {
        name = name.toLocaleLowerCase();

        const filteredAppCategories: Array<ApplicationCategory> = [];

        state.applications()?.categories.forEach((cat) => {
          const appsCopy: Array<Application> = [];

          cat.applications?.forEach((app) => {
            if (app.name.toLocaleLowerCase().includes(name)) {
              appsCopy.push({ ...app });
            }
          });

          if (appsCopy.length) {
            const categoryCopy = {
              ...cat,
              applications: appsCopy,
            } as ApplicationCategory;
            filteredAppCategories.push(categoryCopy);
          }
        });

        return filteredAppCategories;
      },
      filterProductDocuments: (name: string) =>
        state
          .productCategories()
          ?.filter((prod) =>
            prod.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
          ) || null,
      filterServiceDocuments: (name: string) =>
        state
          .serviceDocumentCategories()
          ?.filter((prod) =>
            prod.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
          ) || null,
      getProductDocumentNameFromCode: (code: string) =>
        state.productCategories()?.find((prod) => prod.code === code)?.name ||
        code,
      getProductDocumentIconURLFromCode: (code: string) =>
        state.productCategories()?.find((prod) => prod.code === code)?.icon ||
        '',
      updateApplications: (apps: Application[]) => {
        const cat = state.applications()?.categories;
        return patchState(state, {
          applications: { favorites: apps, categories: cat || [] },
        });
      },
      addFavorite: (app: Application) => {
        const favorites = applicationService.addFavoriteApp(app);
        const cat = state.applications()?.categories;
        patchState(state, {
          applications: { favorites: favorites, categories: cat || [] },
        });
      },
      removeFavorite: (appId: string) => {
        const favorites = applicationService.removeFavoriteApp(appId);
        const cat = state.applications()?.categories;
        patchState(state, {
          applications: { favorites: favorites, categories: cat || [] },
        });
      },
      updateOpenAppsInNewTabUserSetting: (value: boolean) =>
        identityUserService
          .addOrUpdateUserSetting({
            settingKey: 'app-wrapper.apps_newTab',
            value: value,
          })
          .pipe(takeUntilDestroyed(destroyRef))
          .subscribe({
            next: () => {
              patchState(state, { appsNewTabUserSetting: value });
            },
          }),
      load: rxMethod<boolean>((authorized$) =>
        authorized$.pipe(
          switchMap((authorized) => {
            if (!authorized) {
              return NEVER;
            }
            patchState(state, setLoading());
            return combineLatest([
              categoryService.getRootCategories(userService.userContext.lang),
              applicationService.applicationsWithNewIcons,
              categoryService.getServiceCategory(userService.userContext.lang),
              identityUserService.getUserSetting(
                'app-wrapper.apps_newTab',
                '2.0',
              ),
            ]);
          }),
          tap(
            ([
              productCategories,
              applications,
              serviceDocumentCategories,
              appsNewTabUserSetting,
            ]) => {
              function sortAlphabetically(a: ApiCategory, b: ApiCategory) {
                return (a.name || '').localeCompare(b.name || '');
              }

              const productsFilteredAndSorted = productCategories.categories
                ?.filter((cat) => cat.code.toLowerCase() !== 'service')
                .sort(sortAlphabetically);

              const newTabUserSetting =
                appsNewTabUserSetting?.value === null
                  ? true
                  : appsNewTabUserSetting.value;

              patchState(
                state,
                {
                  productCategories: productsFilteredAndSorted,
                  applications: applications,
                  serviceDocumentCategories:
                    serviceDocumentCategories.children.sort(sortAlphabetically),
                  appsNewTabUserSetting: newTabUserSetting,
                },
                setLoaded(),
              );
            },
          ),
          catchError((error) => {
            patchState(state, setError(error.message));
            return EMPTY;
          }),
        ),
      ),
      setBannerVisible() {
        return patchState(state, { bannerVisible: true });
      },
      setBannerHidden() {
        return patchState(state, { bannerVisible: false });
      },
    };
  }),
) {}
