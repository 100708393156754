import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingStatus } from '@ui-kit/loading-status';
import { PermissionDeniedRoute } from '../../routes';
import { RootState } from './root.reducer';

export const featureName = 'rootModule';

export namespace RootSelectors {
  export const rootState = createFeatureSelector<RootState>(featureName);

  export const currentProvisioningId = createSelector(
    rootState,
    (state: RootState) => state.currentProvisioningId,
  );
  export const currentProvisioningIdLoadingStatus = createSelector(
    rootState,
    (state: RootState) => state.currentProvisioningIdLoading,
  );

  export const marketModelRoles = createSelector(
    rootState,
    (state: RootState) => state.marketModelRoles,
  );
  export const marketModelTopRole = createSelector(
    rootState,
    (state: RootState) =>
      state.marketModelRoles &&
      state.marketModelRoles[0] &&
      state.marketModelRoles[0].roleType,
  );
  export const marketModelRolesLoadingStatus = createSelector(
    rootState,
    (state: RootState) => state.marketModelRolesLoading,
  );

  export const routesInformation = createSelector(
    rootState,
    (state: RootState) => state.routesInformation,
  );

  export const homeRouteByModule = (moduleName?: string) =>
    createSelector(routesInformation, (routes) => {
      if (routes) {
        return (
          (moduleName != null &&
            routes.visibleRoutes.find((r) => r.startsWith(moduleName))) ||
          routes.homeRoute
        );
      }
      return PermissionDeniedRoute;
    });

  export const isGlobalReadOnly = createSelector(
    rootState,
    (state: RootState) => state.isGlobalReadOnly,
  );

  export const partners = createSelector(
    rootState,
    (state: RootState) => state.partners,
  );
  export const partnersLoadingStatus = createSelector(
    rootState,
    (state: RootState) => state.partnersLoadingStatus,
  );

  export const selectedPartnerId = createSelector(
    rootState,
    (state) => state.selectedPartnerId,
  );

  export const users = createSelector(
    rootState,
    (state: RootState) => state.users,
  );
  export const usersLoadingStatus = createSelector(
    rootState,
    (state: RootState) => state.usersLoadingStatus,
  );

  export const regions = createSelector(
    rootState,
    (state: RootState) => state.regions,
  );

  export const products = createSelector(
    rootState,
    (state: RootState) => state.products,
  );

  export const countriesAndOems = createSelector(
    rootState,
    (state: RootState) => state.countriesAndOems,
  );

  export const userSettings = createSelector(
    rootState,
    (state) => state.userSettings,
  );

  export const clearCacheStatus = createSelector(
    rootState,
    (state) => state.clearCacheStatus,
  );
  export const clearingCache = createSelector(
    clearCacheStatus,
    (state) => state === LoadingStatus.Loading,
  );
}
