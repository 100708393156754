import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { filterTruthy } from '@shared-lib/rxjs';
import { marked } from 'marked';
import { BehaviorSubject, Subject, finalize, takeUntil } from 'rxjs';
import { PageService, VersionControlRecursionType, WikiPage } from '.';
import { MenuItem, Menu } from '@design-system/feature/app-wrapper-v2';

export interface HelpPagesConfig {
  pagePath: string;
  projectName: string;
  wikiName: string;
  appTitle?: string;
  appIcon?: string;
}

export const HELP_PAGES_CONFIG = new InjectionToken<HelpPagesConfig>(
  'HELP_PAGES_CONFIG',
);

@Injectable({
  providedIn: 'root',
})
export class HelpPagesService implements OnDestroy {
  nav = new BehaviorSubject<{
    rootPath: string;
    sub_pages: WikiPage[];
  }>({ rootPath: '', sub_pages: [] });
  md = marked.setOptions({});

  menu$ = new BehaviorSubject<Menu | undefined>(undefined);
  content$ = new BehaviorSubject<string | undefined>(undefined);
  isMenuLoading$ = new BehaviorSubject<boolean>(true);
  isContentLoading$ = new BehaviorSubject<boolean>(true);
  isHelpPageActive$ = new BehaviorSubject<boolean>(false);

  private _destroy$ = new Subject<void>();
  private _currentMenu: Menu;
  private _lastMenus: Menu[] = [];

  constructor(private _wikiService: PageService) {}

  ngOnDestroy() {
    this._destroy$.next();
  }

  initializeMenu(config: HelpPagesConfig) {
    this.isHelpPageActive$.next(true);
    this.isMenuLoading$.next(true);
    this.isContentLoading$.next(true);
    this._wikiService
      .getPage(
        config.pagePath,
        config.projectName,
        config.wikiName,
        VersionControlRecursionType.Full,
        true,
      )
      .pipe(
        filterTruthy(),
        finalize(() => {
          this.isMenuLoading$.next(false);
          this.isContentLoading$.next(false);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((wiki) => {
        const startPage = {
          title: this._getNameFromPath(wiki?.page?.path),
          icon: 'article',
          onClick: () => {
            if (wiki?.page?.path) this._getPage(wiki.page.path, config);
          },
        };
        const navigationItems = this._mapSubPages(
          wiki?.page?.sub_pages,
          false,
          config,
        );
        const navigation = [startPage, ...navigationItems];
        const menu = {
          title: config.appTitle || '',
          titleIcon: config.appIcon,
          backButtonUrl: '/',
          navigation,
        };
        this._currentMenu = menu;
        this.menu$.next(menu);
        if (wiki) {
          this._setNavigation(config.pagePath, wiki.page?.sub_pages || []);
        }
        this.content$.next(this.md.parse(wiki.page?.content || ''));
      });
  }

  setIsHelpActive(flag: boolean) {
    this.isHelpPageActive$.next(flag);
  }

  getIsHelpActive$() {
    return this.isHelpPageActive$.asObservable();
  }

  getMenu$() {
    return this.menu$.asObservable();
  }

  getLastMenu() {
    return this._lastMenus.pop();
  }

  getIsMenuLoading$() {
    return this.isMenuLoading$.asObservable();
  }

  getContent$() {
    return this.content$.asObservable();
  }

  getIsContentLoading$() {
    return this.isContentLoading$.asObservable();
  }

  private _getPage(path: string, config: HelpPagesConfig) {
    this.isContentLoading$.next(true);
    this._wikiService
      .getPage(
        path,
        config.projectName || '',
        config.wikiName || '',
        VersionControlRecursionType.Full,
        true,
      )
      .pipe(
        finalize(() => {
          this.isContentLoading$.next(false);
        }),
      )
      .subscribe((data) => {
        if (data && !path) {
          this._setNavigation(config.pagePath, data.page?.sub_pages || []);
        }
        this.content$.next(this.md.parse(data.page?.content || ''));
      });
  }

  private _setNavigation(rootPath: string, sub_pages: WikiPage[]): void {
    this.nav.next({ rootPath, sub_pages });
  }

  private _getNameFromPath(path?: string | null): string {
    const pieces = path?.split('/') || 'N/A';
    return pieces[pieces.length - 1];
  }

  private _mapSubPages(
    pages: Array<WikiPage> | null | undefined,
    newNavigation: boolean,
    config: HelpPagesConfig,
  ): Array<MenuItem> {
    return (
      pages?.map((page) => ({
        title: this._getNameFromPath(page?.path),
        icon: 'article',
        onClick: () => {
          if (page?.path) {
            this._getPage(page.path, config);
            if (newNavigation && page.sub_pages?.length) {
              const navigation = this._mapSubPages(
                page.sub_pages,
                false,
                config,
              );
              const menu = {
                title: this._getNameFromPath(page?.path),
                titleIcon: 'article',
                backButtonUrl: '/help',
                navigation,
              };
              this._lastMenus.push(this._currentMenu);
              this._currentMenu = menu;
              this.menu$.next(menu);
            }
          }
        },
        children: page.sub_pages
          ? this._mapSubPages(page.sub_pages, true, config)
          : [],
      })) || []
    );
  }
}
