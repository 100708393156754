<ng-container *ngIf="!!data && control">
  <mat-form-field
    [ngClass]="{ 'no-arrow ': isLoading }"
    class="full-width"
    [hideRequiredMarker]="hideRequiredMarker"
    *ngIf="
      data.length > 2 || isPaginated || isSingleSelectionDisabled;
      else singleSelection
    "
  >
    <mat-label>
      {{ label }}
      <span
        *ngIf="
          ((!!data && data.length > 2) || isPaginated) && !isMultiChoiceDisabled
        "
      >
        ({{ control.value?.length || 0 }}/{{ totalCount || data?.length }})
      </span>
    </mat-label>

    <mat-select
      [panelClass]="'ds-filter-panel'"
      (openedChange)="focusOnChange()"
      [disabled]="isLoading"
      [multiple]="!isMultiChoiceDisabled"
      [formControl]="control"
      [errorStateMatcher]="errorStateMatcher"
      [attr.data-cy]="label + '-select'"
    >
      <mat-select-trigger *ngIf="isPaginated">
        <ng-container *ngIf="isArray(control.value); else singleCustomTrigger">
          <ng-container *ngFor="let value of control.value; let i = index">
            <span *ngIf="dataLookup['' + value]">
              <span *ngIf="i !== 0">, </span
              ><span [textContent]="dataLookup['' + value].viewValue"></span>
            </span>
          </ng-container>
          <ng-container *ngIf="selectedButNotLoadedValues.length !== 0">
            <span
              *ngIf="
                selectedButNotLoadedValues.length === control.value.length;
                else moreLabel
              "
            >
              {{
                'filter_input.selected_label'
                  | translate: { count: selectedButNotLoadedValues.length }
              }}
            </span>
            <ng-template #moreLabel>
              ,
              {{
                'filter_input.more_label'
                  | translate: { count: selectedButNotLoadedValues.length }
              }}
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #singleCustomTrigger>
          <ng-container *ngIf="control.value">
            <span
              *ngIf="dataLookup['' + control.value]; else singleValueLabel"
              [textContent]="dataLookup['' + control.value].viewValue"
            ></span>
          </ng-container>
          <ng-template #singleValueLabel>
            <span class="singe-value">{{ control.value }}</span>
          </ng-template>
        </ng-template>
      </mat-select-trigger>
      <div class="header">
        <div class="label">
          <mat-checkbox
            *ngIf="
              !isSelectAllDisabled &&
                !!data &&
                data.length > 2 &&
                !isPaginated &&
                !isMultiChoiceDisabled;
              else headerLabel
            "
            #checkBox
            [indeterminate]="
              !!control.value &&
              !!data &&
              control.value.length > 0 &&
              control.value.length < data.length
            "
            [checked]="control.value?.length === data?.length"
            (change)="toggleAllSelection()"
          >
            <strong>
              {{ label }}

              <span *ngIf="(!!data && data.length > 2) || isPaginated">
                ({{ control.value?.length || 0 }}/{{
                  totalCount || data?.length
                }})
              </span>
            </strong>
          </mat-checkbox>

          <ng-template #headerLabel>
            <strong>
              {{ label }}
              <span *ngIf="!!data && data.length > 2 && !isMultiChoiceDisabled"
                >({{ control.value?.length || 0 }}/{{ data?.length }})</span
              >
            </strong>
          </ng-template>
        </div>
        <div fxLayout="row">
          <input
            #inputSelect
            [id]="label + '_search'"
            class="mat-mdc-input-element mat-mdc-form-field-input-control custom-input"
            type="text"
            *ngIf="(!!data && data.length > 6) || isPaginated"
            [placeholder]="'customer.btn_search' | translate"
            [formControl]="searchControl"
            data-cy="filter-search"
            (keydown)="$event.stopPropagation()"
          />
          <button
            matIconSuffix
            mat-icon-button
            *ngIf="searchControl.value"
            type="button"
            data-cy="search-clear-button"
            (click)="searchControl.reset()"
          >
            <mat-icon matIconSuffix>clear</mat-icon>
          </button>
        </div>
      </div>
      <div class="selection-panel" #scrollPanel>
        <ng-container *ngFor="let option of data">
          <mat-option
            [value]="option.value"
            [class.hidden-option]="
              !!searchControl.value &&
              !option.viewValue
                .toLocaleLowerCase()
                .includes(searchControl.value.toLocaleLowerCase())
            "
            [attr.data-cy]="option.viewValue + '-option'"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              [fxLayoutGap]="0.25 | dsSpacing"
            >
              <span
                *ngIf="option.icon as icon"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="icon-placeholder"
              >
                <img
                  class="icon"
                  height="20"
                  *ngIf="
                    icon.indexOf('assets') !== -1 ||
                      icon.indexOf('http') !== -1;
                    else (icon | isPdIcon)
                      ? pdIcon
                      : (icon | isEquipmentIcon)
                        ? equipmentIcon
                        : matIconTemplate
                  "
                  [src]="icon"
                />
                <ng-template #matIconTemplate>
                  <mat-icon class="icon">{{ icon }}</mat-icon>
                </ng-template>
                <ng-template #pdIcon>
                  <mat-icon
                    class="icon"
                    fontSet="pd"
                    [fontIcon]="icon"
                  ></mat-icon>
                </ng-template>
                <ng-template #equipmentIcon>
                  <ds-equipment-icon [type]="icon"></ds-equipment-icon>
                </ng-template>
              </span>
              <span
                class="nowrap-text"
                [ngClass]="{
                  'error-text': option.textIndicators === 'error',
                  'warning-text': option.textIndicators === 'warning',
                  'info-text': option.textIndicators === 'info',
                  'success-text': option.textIndicators === 'success'
                }"
                [textContent]="option.viewValue"
              ></span>
            </div>
          </mat-option>
        </ng-container>

        <mat-spinner
          class="data-loading-spinner"
          *ngIf="isDataLoading"
          matTextSuffix
          color="accent"
          [diameter]="20"
        ></mat-spinner>

        <mat-option
          disabled
          *ngIf="data?.length === 0"
          [class.hidden-option]="!showNotAvailable"
          >{{ 'general.not_available' | translate }}</mat-option
        >

        <ng-content></ng-content>
      </div>
      <ng-container *ngIf="isPaginated">
        <mat-option
          *ngFor="let value of selectedButNotLoadedValues"
          [value]="value"
          class="hidden-option"
        ></mat-option>
      </ng-container>
    </mat-select>

    <mat-spinner
      color="accent"
      matTextSuffix
      *ngIf="isLoading"
      diameter="20"
    ></mat-spinner>
    <button
      mat-icon-button
      matIconSuffix
      (click)="clear($event); checkBox ? (checkBox.checked = false) : ''"
      *ngIf="(control.value | showClearButton) && !control.disabled"
      [matTooltip]="'general.clear' | translate"
      class="clear-btn"
      type="button"
      [attr.data-cy]="label + '-clear-button'"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>
      <ng-content select="“[‘mat-error’]“"></ng-content>
    </mat-error>
    <mat-hint>
      <ng-content select="“[‘mat-hint’]“"></ng-content>
    </mat-hint>
  </mat-form-field>
  <ng-template #singleSelection>
    <mat-form-field
      [ngClass]="{ 'no-arrow ': isLoading }"
      class="full-width"
      [hideRequiredMarker]="hideRequiredMarker"
    >
      <mat-label>
        {{ label }}
        <span
          *ngIf="
            ((!!data && data.length > 2) || isPaginated) &&
            !isMultiChoiceDisabled
          "
        >
          ({{ control.value?.length || 0 }}/{{ totalCount || data?.length }})
        </span>
      </mat-label>

      <mat-select
        [panelClass]="'ds-filter-panel'"
        [disabled]="isLoading"
        [formControl]="control"
        [errorStateMatcher]="errorStateMatcher"
        [attr.data-cy]="label + '-select'"
      >
        <mat-option
          *ngFor="let option of data"
          [value]="option.value"
          [class.hidden-option]="
            !!searchControl.value &&
            !option.viewValue
              .toLocaleLowerCase()
              .includes(searchControl.value.toLocaleLowerCase())
          "
          [attr.data-cy]="option.viewValue + '-option'"
        >
          <div *ngIf="option.icon as icon">
            <img
              *ngIf="
                icon.indexOf('assets') !== -1 || icon.indexOf('http') !== -1;
                else (icon | isPdIcon)
                  ? pdIcon
                  : (icon | isEquipmentIcon)
                    ? equipmentIcon
                    : matIconTemplate
              "
              lass="icon"
              [src]="icon"
            />
            <ng-template #matIconTemplate>
              <mat-icon class="icon">{{ icon }}</mat-icon>
            </ng-template>
            <ng-template #pdIcon>
              <mat-icon class="icon" fontSet="pd" [fontIcon]="icon"></mat-icon>
            </ng-template>
            <ng-template #equipmentIcon>
              <ds-equipment-icon [type]="icon"></ds-equipment-icon>
            </ng-template>
          </div>
          <span
            [ngClass]="{
              'error-text': option.textIndicators === 'error',
              'warning-text': option.textIndicators === 'warning',
              'info-text': option.textIndicators === 'info',
              'success-text': option.textIndicators === 'success'
            }"
            class="nowrap-text"
            [textContent]="option.viewValue"
          ></span>
        </mat-option>
        <mat-option disabled *ngIf="data?.length === 0">{{
          'general.not_available' | translate
        }}</mat-option>
      </mat-select>

      <mat-spinner
        color="accent"
        matTextSuffix
        *ngIf="isLoading"
        diameter="20"
      ></mat-spinner>
      <button
        mat-icon-button
        matIconSuffix
        (click)="clear($event); checkBox ? (checkBox.checked = false) : ''"
        *ngIf="(control.value | showClearButton) && !control.disabled"
        [matTooltip]="'general.clear' | translate"
        class="clear-btn"
        type="button"
        [attr.data-cy]="label + '-clear-button'"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error>
        <ng-content select="“[‘mat-error’]“"></ng-content>
      </mat-error>
      <mat-hint>
        <ng-content select="“[‘mat-hint’]“"></ng-content>
      </mat-hint>
    </mat-form-field>
  </ng-template>
</ng-container>
