import { Injectable } from '@angular/core';
/**
 * Provides basic services to retrieve languages
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  /**
   * all languages codes which are supported for user interface translations
   */
  private readonly userInterfaceLanguageCodes = [
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fi',
    'fr',
    'hr',
    'hu',
    'it',
    'is',
    'ja',
    'lt',
    'lv',
    'no',
    'nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sv',
    'tr',
    'uk',
    'zh',
    'zh-tw',
  ];

  /* used languages in common json file*/
  private usedUserInterfaceLanguageCodes = [
    'de',
    'en',
    'es',
    'et',
    'fi',
    'fr',
    'it',
    'ja',
    'lt',
    'pt',
    'ru',
    'sv',
    'zh',
  ];

  /**
   * all languages which are used in paldesk
   */
  private readonly LANGUAGES: Language[] = [
    { code: 'be', title: 'Belgian' },
    { code: 'bz', title: 'Belizean' },
    { code: 'de', title: 'Deutsch' },
    { code: 'en', title: 'English' },
    { code: 'es', title: 'Español' },
    { code: 'fr', title: 'Français' },
    { code: 'pt', title: 'Português' },
    { code: 'ru', title: 'Русский' },
    { code: 'it', title: 'Italiano' },
    { code: 'is', title: 'Islenska' },
    { code: 'dk', title: 'Dansk' },
    { code: 'da', title: 'Dansk' },
    { code: 'no', title: 'Norsk' },
    { code: 'nb', title: 'Norsk' },
    { code: 'fi', title: 'Suomalainen' },
    { code: 'zh', title: '中文' },
    { code: 'nl', title: 'Nederlands' },
    { code: 'sv', title: 'Svensk' },
    { code: 'sr', title: 'Српски' },
    { code: 'cn', title: '中文' },
    { code: 'se', title: 'Svenska' },
    { code: 'lv', title: 'Latviešu' },
    { code: 'sk', title: 'Slovenský' },
    { code: 'tr', title: 'Türkçe' },
    { code: 'cz', title: 'český' },
    { code: 'et', title: 'Eesti' },
    { code: 'ja', title: '日本語' },
    { code: 'ua', title: 'Ukrajina' },
    { code: 'uk', title: 'Ukrajina' },
    { code: 'ar', title: 'العَرَبِية' },
    { code: 'sl', title: 'Slovenščina' },
    { code: 'pl', title: 'Polski' },
    { code: 'vi', title: 'Tiếng Việt' },
    { code: 'bg', title: 'Bălgarski' },
    { code: 'cs', title: 'český' },
    { code: 'jp', title: '日本語' },
    { code: 'ee', title: 'Eesti' },
    { code: 'el', title: 'Eλληνική' },
    { code: 'lt', title: 'Lietuvių' },
    { code: 'sk', title: 'Slovenský' },
    { code: 'tr', title: 'Türkçe' },
    { code: 'hr', title: 'Hrvatski' },
    { code: 'hu', title: 'Magyar' },
    { code: 'ko', title: '한국말' },
    { code: 'ro', title: 'Română' },
    { code: 'zh-tw', title: '国语' },
  ];

  /**
   * Return a list of all availables/used languages
   */
  getLanguages(): Language[] {
    return this.LANGUAGES;
  }
  /**
   * Returns a language by code
   * @param code
   */
  findLanguageByCode(code: string): Language | undefined {
    return this.LANGUAGES.find(
      (x) => x.code.toLowerCase() === code.toLowerCase(),
    );
  }

  /**
   * Returns available langauges which are used for the interface translations
   */
  getPaldeskUserInterfaceLanguages(): Language[] {
    return this.findLanguagesByCodes(this.userInterfaceLanguageCodes);
  }

  /**
   * Returns available langauges which are used for the interface translations
   */
  getUsedPaldeskUserInterfaceLanguages(): Language[] {
    return this.findLanguagesByCodes(this.usedUserInterfaceLanguageCodes);
  }

  private findLanguagesByCodes(languageCodes: string[]): Language[] {
    const userInterfaceLanguages: Language[] = [];
    languageCodes.forEach((element) => {
      const foundLanguage = this.findLanguageByCode(element);
      if (foundLanguage !== undefined) {
        userInterfaceLanguages.push(foundLanguage);
      }
    });
    return userInterfaceLanguages;
  }

  setUsedPaldeskUserInterfaceLanguages(languages: string[]): void {
    this.usedUserInterfaceLanguageCodes = languages;
  }
  /**
   * Returns only codes like en|de
   */
  getPaldeskUserInterfaceLanguageCodes(): string[] {
    return this.userInterfaceLanguageCodes;
  }
}

export interface Language {
  code: string;
  title: string;
}
