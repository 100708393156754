import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoadingStatus } from '@ui-kit/loading-status';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { RootSelectors, RootState } from '../shared/store';

@Component({
  selector: 'mm-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean | LoadingStatus.NoData | undefined>;

  private destroy$ = new Subject<void>();

  constructor(
    private rootStore: Store<RootState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.loading$ = combineLatest([
      this.rootStore.select(RootSelectors.currentProvisioningIdLoadingStatus),
      this.rootStore.select(RootSelectors.marketModelRolesLoadingStatus),
    ]).pipe(
      map(
        (loadings) =>
          (loadings[0] && loadings[0] === LoadingStatus.Loading) ||
          (loadings[1] && loadings[1] === LoadingStatus.Loading),
      ),
    );
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        map((params) => params && params.module),
        switchMap((moduleName) =>
          this.rootStore.select(RootSelectors.homeRouteByModule(moduleName)),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((homeRoute) => {
        this.router.navigateByUrl(homeRoute);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
