export namespace TaskAssignmentRoutes {
  export const root = 'tasks';
  export const provisionings = 'provisionings';
  export const coordinators = 'coordinators';
  export const inputAssignment = 'inputassignment';
}

export namespace MarketInputRoutes {
  export const root = 'marketinput';
  export const regionProductStatus = 'regionproductstatus';
  export const inputStatus = 'inputstatus';
  export const inputDetail = 'inputdetail';
}

export const PermissionDeniedRoute = 'special/permissiondenied';
