import { Injectable } from '@angular/core';
import { UserApplicationsService } from '@data-access/bpm-generated';
import { TranslateService } from '@ngx-translate/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  Application,
  ApplicationCategory,
  ApplicationContainer,
} from './application.models';

interface GetApplicationsResponse {
  categories: ApplicationCategory[];
  favorites: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  // List of Users Applications
  private _applications: BehaviorSubject<ApplicationContainer | null>;

  constructor(
    private userApplicationsService: UserApplicationsService,
    private messengerService: MessengerService,
    private translateService: TranslateService,
  ) {
    this._applications = new BehaviorSubject<ApplicationContainer | null>(null);
  }
  /**
   * Returns only applications
   */
  get applications(): Observable<ApplicationContainer | null> {
    if (this._applications.getValue() === null) {
      this.loadUserApplications(false);
    }
    return this._applications.asObservable();
  }

  get applicationsWithNewIcons(): Observable<ApplicationContainer | null> {
    if (this._applications.getValue() === null) {
      this.loadUserApplications(true);
    }
    return this._applications.asObservable();
  }

  static removeFavoriteUpdatingFlagFromAppContainer(
    applicationContainer: ApplicationContainer,
  ): void {
    ApplicationService.removeFavoriteUpdatingFlag(
      applicationContainer.favorites,
    );

    applicationContainer.categories.forEach((category) =>
      ApplicationService.removeFavoriteUpdatingFlag(category.applications),
    );
  }

  static removeFavoriteUpdatingFlag(applications: Application[]): void {
    applications.forEach((app) => (app.isUpdatingFavorite = false));
  }

  /**
   * Add App to Favorites
   * @param app
   */
  addFavoriteApp(app: Application): Application[] {
    const tempFavs_ = this._applications.getValue();
    if (tempFavs_) {
      const tempFavs = tempFavs_.favorites.slice();
      if (tempFavs.indexOf(app) === -1) {
        tempFavs.push(app);
        this.saveFavoriteApps(tempFavs);
      }
      return tempFavs;
    } else {
      return [] as Application[];
    }
  }
  /**
   * Removes App from Favorites
   * @param appId sitecore app id
   */
  removeFavoriteApp(appId: string): Application[] {
    const tempFavs_ = this._applications.getValue();
    if (tempFavs_) {
      const tempFavs = tempFavs_.favorites.slice();
      tempFavs.forEach((el, i) => {
        if (el.id === appId) {
          tempFavs.splice(i, 1);
          this.saveFavoriteApps(tempFavs);
        }
      });
      return tempFavs;
    } else {
      return [] as Application[];
    }
  }

  /**
   * ReOrder the user favorites
   * @param sourceId Application
   * @param targetId Application
   */
  reOrderFavoritesApps(
    source: Application,
    target: Application,
  ): Application[] {
    const tempFavs_ = this._applications.getValue();
    if (tempFavs_) {
      const tempFavs: Application[] | undefined = tempFavs_.favorites.slice();
      let src: number = tempFavs.findIndex((x) => x.id === source.id);
      const trg: number = tempFavs.findIndex((x) => x.id === target.id);
      if (src !== trg) {
        if (src === -1) {
          tempFavs.push(source);
          src = tempFavs.findIndex((x) => x.id === source.id);
        }

        if (src !== -1 && trg !== -1 && trg >= tempFavs.length) {
          let k = trg - tempFavs.length;
          while (k-- + 1) {
            tempFavs.push({} as Application);
          }
        }
        tempFavs.splice(trg, 0, tempFavs.splice(src, 1)[0]);
        this.saveFavoriteApps(tempFavs);
      }
      return tempFavs;
    } else {
      return [] as Application[];
    }
  }

  /**
   * Saves user Favorites
   */
  saveFavoriteApps(apps: Application[]): Subscription {
    const idOfApps = apps.map((x) => x.id);

    return this.userApplicationsService
      .bpmUpdateUserFavoriteApplications('3.0', idOfApps)
      .subscribe(
        () => {
          const applications = this._applications.getValue();
          if (applications) {
            applications.favorites = apps;
            this.recomputeFavoriteInApplications(
              idOfApps,
              applications.categories,
            );
            ApplicationService.removeFavoriteUpdatingFlagFromAppContainer(
              applications,
            );
            this._applications.next(applications);
          }
        },
        () => {
          ApplicationService.removeFavoriteUpdatingFlag(apps);
          this.messengerService.sendMessage(
            MessageSeverityType.error,
            this.translateService.instant('general.error_code.error'),
            MessageTargetType.toast,
          );
        },
      );
  }

  /**
   * Returns Users Applications based on User Permissions
   */
  private loadUserApplications(newIcons: boolean): void {
    this.userApplicationsService
      .bpmGetUserApplications('3.0', newIcons)
      .subscribe((res) => {
        this._applications.next(
          this.mapGetApplicationsResponseToApplicationContainer(
            res as GetApplicationsResponse,
          ),
        );
      });
  }

  private recomputeFavoriteInApplications(
    newFavoritesIds: string[],
    categories: ApplicationCategory[],
  ): void {
    categories.forEach((cat) =>
      cat.applications.forEach(
        (app) => (app.favorite = newFavoritesIds.includes(app.id)),
      ),
    );
  }

  private mapGetApplicationsResponseToApplicationContainer(
    res: GetApplicationsResponse,
  ): ApplicationContainer {
    this.recomputeFavoriteInApplications(res.favorites, res.categories);

    const favorites: Application[] = [];
    res.favorites.forEach((favoriteId) => {
      res.categories.forEach((cat) => {
        cat.applications.forEach((app) => {
          if (app.id === favoriteId) {
            favorites.push(app);
          }
        });
      });
    });

    return {
      categories: res.categories,
      favorites,
    };
  }
}
